import React from "react";

const TokenizedGovernancePage = () => {
  return (
    <section className="min-h-screen bg-gray-1000 text-white flex items-center justify-center px-6 py-16">
      <div className="max-w-7xl mx-auto grid grid-cols-1 md:grid-cols-2 gap-12 items-center">
        {/* Left Section */}
        <div>
          <h1 className="text-4xl font-bold mb-6">
            Tokenized Governance & Rewards
          </h1>
          <p className="text-lg text-gray-300 mb-6 leading-relaxed">
            Empowering Community Through Tokenized Governance
            <br />
            DevTrust introduces a decentralized approach to decision-making and
            rewards. Our governance model ensures that every token holder has a
            voice in shaping the platform's future.
          </p>

          {/* Buttons/Highlights */}
          <div className="space-y-4">
            <button className="flex items-center gap-3 bg-gray-800 text-white px-4 py-2 rounded-lg shadow-lg hover:bg-gray-700">
              <span>🗳️</span> Vote on Platform Features
            </button>
            <button className="flex items-center gap-3 bg-gray-800 text-white px-4 py-2 rounded-lg shadow-lg hover:bg-gray-700">
              <span>📊</span> Transparent Treasury Management
            </button>
            <button className="flex items-center gap-3 bg-gray-800 text-white px-4 py-2 rounded-lg shadow-lg hover:bg-gray-700">
              <span>💰</span> Earn and Multiply Rewards
            </button>
          </div>
        </div>

        {/* Right Section */}
        <div className="flex justify-end relative">
            
  <img
    src="/TokenisedPage.png" // Replace with your PNG file path
    alt="Tokenized Governance Dashboard"
    className="w-auto max-w-[100%] md:max-w-[100%] lg:max-w-[100%] h-auto rounded-lg"
    style={{ objectFit: 'contain', marginRight: '-13%' }} // Ensures it stays visually right-aligned
  />
</div>

      </div>
    </section>
  );
};

export default TokenizedGovernancePage;
