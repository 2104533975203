// src/components/Messenger/ChatList.js
import React from 'react';
import { ChevronLeft, Search, User } from 'lucide-react';

const ChatList = ({ chats, selectedChat, onChatSelect, user }) => {
  return (
    <div className="w-1/4 bg-white border-r overflow-hidden">
      <div className="p-4 border-b">
        <div className="flex items-center space-x-3">
          <ChevronLeft className="w-5 h-5 text-gray-500" />
          <h1 className="text-xl font-semibold">Chat</h1>
        </div>
        <div className="mt-4 relative">
          <input
            type="text"
            placeholder="Search chats..."
            className="w-full px-4 py-2 bg-gray-100 rounded-lg pl-10"
          />
          <Search className="absolute left-3 top-2.5 w-5 h-5 text-gray-400" />
        </div>
      </div>
      <div className="overflow-y-auto h-[calc(100vh-64px)]">
        {chats.map(chat => {
          const otherUser = chat.ownerId === user.id ? 'Developer' : 'Project Owner'; // Use user.id here
          const hasProposal = chat.proposal?.status === 'pending';
          const isOwner = chat.ownerId === user.id; // Use user.id here

          return (
            <div
              key={chat._id}
              onClick={() => onChatSelect(chat)}
              className={`p-4 border-b cursor-pointer hover:bg-gray-50 ${
                selectedChat?._id === chat._id ? 'bg-blue-50' : ''
              }`}
            >
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <User className="w-6 h-6 text-gray-500" />
                  <div className="ml-3">
                    <p className="font-medium">{otherUser}</p>
                    <p className="text-xs text-gray-500">Project: {chat.project?.title}</p>
                  </div>
                </div>
                {hasProposal && (
                  <span className={`text-sm ${isOwner ? 'text-blue-500' : 'text-yellow-500'}`}>
                    {isOwner ? 'Action Required' : 'Pending'}
                  </span>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ChatList;