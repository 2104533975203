// src/pages/messenger/MessengerPage.jsx
import React, { useState, useEffect, useRef } from 'react';
import { useAuth } from '../../context/AuthContext';
import socket from '../../services/socket';
import api from '../../services/api';
import ChatList from '../../components/Messenger/ChatList';
import ChatHeader from '../../components/Messenger/ChatHeader';
import MessageArea from '../../components/Messenger/MessageArea';
import MessageInput from '../../components/Messenger/MessageInput';
import RightSidebar from '../../components/Messenger/RightSidebar';
import ProposalSection from '../../components/Messenger/ProposalSection';

const MessengerPage = () => {
  const [chats, setChats] = useState([]);
  const [selectedChat, setSelectedChat] = useState(null);
  const [messages, setMessages] = useState([]);
  const [messageInput, setMessageInput] = useState('');
  const messagesEndRef = useRef(null);
  const { user } = useAuth();
  const [showScrollButton, setShowScrollButton] = useState(false);

  // Connect socket and set up message handling on component mount
  useEffect(() => {
    if (!socket.connected) {
      socket.auth = { token: localStorage.getItem('token') };
      socket.connect();
    }

    socket.on('messageReceived', handleNewMessage);
    socket.on('proposalStatusUpdated', handleProposalUpdated);

    return () => {
      socket.off('messageReceived');
      socket.off('proposalStatusUpdated');
    };
  }, []);

  // Handle new messages received from socket
  const handleNewMessage = (message) => {
    console.log('📩 Received message:', message);
    
    if (selectedChat?._id === message.chatId) {
      setMessages((prev) => [...prev, message]);
      if (!isScrolledUp) {
        scrollToBottom();
      }
    }

    // Update chat list with latest message
    setChats((prev) =>
      prev.map((chat) =>
        chat._id === message.chatId
          ? { ...chat, lastMessage: message }
          : chat
      )
    );
  };

  // Handle proposal status updates
  const handleProposalUpdated = ({ chatId, status }) => {
    setChats((prev) =>
      prev.map((chat) =>
        chat._id === chatId
          ? { ...chat, proposal: { ...chat.proposal, status } }
          : chat
      )
    );
  };

  // Fetch chats on component mount
  useEffect(() => {
    const fetchChats = async () => {
      try {
        const response = await api.get('/messenger/chats');
        setChats(response.data);

        if (response.data.length > 0) {
          const firstChat = response.data[0];
          setSelectedChat(firstChat);
          await fetchMessages(firstChat._id);
        }
      } catch (error) {
        console.error('Error fetching chats:', error);
      }
    };

    fetchChats();
  }, []);

  // Fetch messages for a specific chat
  const fetchMessages = async (chatId) => {
    try {
      const response = await api.get(`/messenger/chats/${chatId}/messages`);
      setMessages(response.data);
      scrollToBottom();
    } catch (error) {
      console.error('Error fetching messages:', error);
    }
  };

  // Handle chat selection
  const handleChatSelect = async (chat) => {
    setSelectedChat(chat);
    await fetchMessages(chat._id);
  };

  // Handle sending a new message
  const handleSendMessage = async (e) => {
    e.preventDefault();
    if (!messageInput.trim() || !selectedChat) {
      console.warn('⚠️ No message content or chat selected');
      return;
    }
  
    try {
      if (!selectedChat.participants || !Array.isArray(selectedChat.participants)) {
        console.error('❌ Participants array is missing in selectedChat:', selectedChat);
        return;
      }
  
      const recipient = selectedChat.participants.find(
        (participantId) => participantId !== user.id
      );
  
      if (!recipient) {
        console.error('❌ Recipient could not be determined:', selectedChat);
        return;
      }
  
      const messageData = {
        chatId: selectedChat._id,
        content: messageInput,
      };
  
      console.log('📤 Sending message:', messageData);
  
      const response = await api.post('/messenger/send', messageData);
  
      socket.emit('sendMessage', {
        chatId: selectedChat._id,
        message: response.data,
      });
  
      setMessageInput('');
      setMessages((prev) => [...prev, response.data]);
      scrollToBottom();
    } catch (error) {
      console.error('❌ Error sending message:', error.response?.data || error.message);
    }
  };
  

  // Handle proposal actions
  const handleAcceptProposal = async () => {
    if (!selectedChat) return;
    
    try {
      await api.patch(`/messenger/chats/${selectedChat._id}/proposal/accept`);
      // Socket event will handle the UI update
    } catch (error) {
      console.error('Error accepting proposal:', error);
    }
  };

  const handleDeclineProposal = async () => {
    if (!selectedChat) return;

    try {
      await api.patch(`/messenger/chats/${selectedChat._id}/proposal/decline`);
      // Socket event will handle the UI update
    } catch (error) {
      console.error('Error declining proposal:', error);
    }
  };

  // Scroll handling
  const isScrolledUp = useRef(false);
  
  const handleScroll = (e) => {
    const bottom = Math.abs(
      e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight
    ) < 1;
    isScrolledUp.current = !bottom;
    setShowScrollButton(!bottom);
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className="flex h-screen bg-gray-100">
      <ChatList
        chats={chats}
        selectedChat={selectedChat}
        onChatSelect={handleChatSelect}
        user={user}
      />

      <div className="flex-1 flex flex-col">
        {selectedChat ? (
          <>
            <ChatHeader selectedChat={selectedChat} />
            
            {selectedChat.proposal?.status === 'pending' && (
              <ProposalSection
                selectedChat={selectedChat}
                onAcceptProposal={handleAcceptProposal}
                onDeclineProposal={handleDeclineProposal}
                user={user}
              />
            )}
            
            <MessageArea
              messages={messages}
              user={user}
              messagesEndRef={messagesEndRef}
              onScroll={handleScroll}
              showScrollButton={showScrollButton}
              scrollToBottom={scrollToBottom}
            />
            
            <MessageInput
              messageInput={messageInput}
              setMessageInput={setMessageInput}
              onSendMessage={handleSendMessage}
            />
          </>
        ) : (
          <div className="flex-1 flex items-center justify-center text-gray-500">
            Select a conversation to start chatting
          </div>
        )}
      </div>

      <RightSidebar />
    </div>
  );
};

export default MessengerPage;