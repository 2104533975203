import React from "react";
import { motion } from "framer-motion";

const SecurePayments = () => {
  // Animation variants for scroll-in effects
  const containerVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.8 } },
  };

  return (
    <section className="min-h-screen bg-gray-1000 text-white px-6 py-16 flex flex-col items-center">
      {/* Heading */}
      <motion.div
        className="text-center mb-12"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.5 }}
        variants={containerVariants}
      >
        <h1 className="text-4xl font-bold">
          Secure, Transparent Payments <br />
          <span className="text-blue-500">With Blockchain</span>
        </h1>
        <p className="text-lg text-gray-300 mt-4 max-w-2xl">
          This system uses blockchain technology to facilitate secure and
          transparent payments for milestone-based projects. Clients deposit
          funds into a smart contract escrow, ensuring developers are paid only
          upon verified completion of pre-defined milestones. This automated
          process eliminates trust issues and provides a transparent, auditable
          record of all transactions, fostering a fair and secure environment
          for both clients and developers.
        </p>
      </motion.div>

      {/* Image */}
      <motion.div
        className="flex justify-center mt-8"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.5 }}
        variants={containerVariants}
      >
        <img
          src="PaymentSecure.png" // Replace with your actual image path
          alt="Secure Payment Workflow"
          className="w-full max-w-4xl rounded-lg shadow-lg"
        />
      </motion.div>
    </section>
  );
};

export default SecurePayments;
