// src/components/Messenger/MessageArea.js
import React from 'react';
import { User } from 'lucide-react';

const MessageArea = ({ messages, user, messagesEndRef, onScroll, showScrollButton, scrollToBottom }) => {
  const renderMessage = (message, index) => {
    const isCurrentUser = message.sender._id === user.id;
    return (
      <div
        key={`message-${message._id}-${index}`}
        className={`flex items-start mb-4 ${isCurrentUser ? 'justify-end' : 'justify-start'}`}
      >
        {!isCurrentUser && (
          <div className="flex-shrink-0 mr-2">
            <div className="w-8 h-8 rounded-full bg-gray-200 flex items-center justify-center">
              <User className="w-4 h-4 text-gray-500" />
            </div>
          </div>
        )}

        <div className={`max-w-[70%] ${isCurrentUser ? 'ml-auto' : ''}`}>
          <div className={`rounded-lg px-4 py-2 ${
            isCurrentUser ? 'bg-blue-500 text-white' : 'bg-gray-100'
          }`}>
            {message.content}
          </div>
          <div className={`text-xs text-gray-500 mt-1 ${isCurrentUser ? 'text-right' : 'text-left'}`}>
            {new Date(message.timestamp).toLocaleTimeString()}
          </div>
        </div>

        {isCurrentUser && (
          <div className="flex-shrink-0 ml-2">
            <div className="w-8 h-8 rounded-full bg-gray-200 flex items-center justify-center">
              <User className="w-4 h-4 text-gray-500" />
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <div
      className="flex-1 overflow-y-auto p-4"
      onScroll={onScroll}
    >
      {messages.map((message, index) => renderMessage(message, index))}
      <div ref={messagesEndRef} />
      {showScrollButton && (
        <button
          onClick={scrollToBottom}
          className="absolute bottom-20 right-8 bg-blue-500 text-white p-2 rounded-full shadow-lg"
        >
          ↓
        </button>
      )}
    </div>
  );
};

export default MessageArea;