import React from 'react';
import { useNavigate } from 'react-router-dom';

const Success = () => {
  const navigate = useNavigate();

  const handleFinish = () => {
    navigate('/dashboard');
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="bg-white p-6 rounded shadow-md w-80">
        <h2 className="text-xl font-bold mb-4 text-gray-800">You're All Set!</h2>
        <p className="text-gray-600 mb-6">
          Your account has been successfully set up. You can now start exploring DevTrust.
        </p>
        <button
          onClick={handleFinish}
          className="w-full bg-blue-600 text-white py-2 rounded hover:bg-blue-700"
        >
          Go to Dashboard
        </button>
      </div>
    </div>
  );
};

export default Success;
