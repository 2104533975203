// src/pages/Orders/OrdersList.js
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import api from '../../services/api';
import { Clock, CheckCircle, AlertTriangle, DollarSign } from 'lucide-react';

const OrdersList = ({ isDev = false }) => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await api.get(isDev ? '/orders/dev' : '/orders');
        setOrders(response.data);
      } catch (error) {
        console.error('Failed to fetch orders:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchOrders();
  }, [isDev]);

  const getStatusBadge = (status) => {
    const badges = {
      pending_payment: { color: 'bg-yellow-100 text-yellow-800', icon: Clock },
      in_progress: { color: 'bg-blue-100 text-blue-800', icon: Clock },
      completed: { color: 'bg-green-100 text-green-800', icon: CheckCircle },
      disputed: { color: 'bg-red-100 text-red-800', icon: AlertTriangle }
    };
    const badge = badges[status] || badges.in_progress;
    const Icon = badge.icon;
    
    return (
      <span className={`flex items-center gap-1 px-2 py-1 rounded-full text-sm ${badge.color}`}>
        <Icon size={14} />
        {status.replace('_', ' ')}
      </span>
    );
  };

  return (
    <div className="p-6">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold">
          {isDev ? 'Orders to Deliver' : 'Your Orders'}
        </h1>
      </div>

      {loading ? (
        <div className="text-center">Loading orders...</div>
      ) : (
        <div className="grid gap-6">
          {orders.map(order => (
            <Link 
              key={order._id}
              to={`/orders/${order._id}`}
              className="block bg-white rounded-lg shadow hover:shadow-md transition-shadow"
            >
              <div className="p-6">
                <div className="flex justify-between items-start">
                  <div>
                    <h3 className="text-lg font-semibold mb-2">{order.project?.title}</h3>
                    <div className="flex items-center gap-4 text-sm text-gray-500">
                      <span className="flex items-center gap-1">
                        <DollarSign size={16} />
                        ${order.amount}
                      </span>
                      <span>Created: {new Date(order.createdAt).toLocaleDateString()}</span>
                    </div>
                  </div>
                  {getStatusBadge(order.status)}
                </div>
              </div>
            </Link>
          ))}
        </div>
      )}
    </div>
  );
};

export default OrdersList;