// frontend/src/services/aiService.js
import api from './api';

/**
 * Generate complete project suggestions from description
 * @param {string} description - The project description
 * @returns {Promise<Object>} The project suggestions
 */
export const generateProjectSuggestions = async (description) => {
  try {
    const response = await api.post('/ai/suggestions', { description });

    // If the AI service fails to provide complete data, provide a structured response
    if (!response.data || !response.data.technicalStack) {
      return {
        title: response.data?.title || description,
        technicalStack: {
          frontend: ["React Native", "Redux", "UI Kit"],
          backend: ["Node.js", "Express", "REST APIs"],
          database: ["MongoDB", "Redis"],
          mobile: ["React Native", "Native APIs"],
          devops: ["AWS", "Docker", "CI/CD"]
        },
        coreFeatures: [
          {
            name: "User Authentication",
            description: "Complete user authentication flow",
            components: ["Login UI", "Registration Form", "Password Recovery"],
            apis: ["/api/auth/login", "/api/auth/register"]
          },
          {
            name: "Product Management",
            description: "Core product features",
            components: ["Product List", "Details View", "Shopping Cart"],
            apis: ["/api/products", "/api/cart"]
          }
        ],
        milestones: [
          {
            title: "UI/UX Design",
            category: "Design",
            description: "Complete application design and wireframes",
            timeline: "2 weeks",
            tasks: [
              "User flow diagrams",
              "Wireframe creation",
              "Design system setup"
            ]
          },
          {
            title: "Backend Development",
            category: "Programming",
            description: "Core backend implementation",
            timeline: "3 weeks",
            tasks: [
              "Database setup",
              "API development",
              "Authentication system"
            ]
          },
          {
            title: "Testing & Launch",
            category: "Testing / QA",
            description: "Final testing and deployment",
            timeline: "2 weeks",
            tasks: [
              "Integration testing",
              "User acceptance testing",
              "Production deployment"
            ]
          }
        ],
        budget: {
          estimate: "$30,000 - $45,000",
          breakdown: {
            design: "$8,000",
            frontend: "$15,000",
            backend: "$12,000",
            infrastructure: "$5,000",
            testing: "$5,000"
          }
        }
      };
    }

    return response.data;
  } catch (error) {
    console.error('Error in generateProjectSuggestions:', error);
    throw error;
  }
};