// src/components/Messenger/ChatHeader.js
import React from 'react';
import { Settings } from 'lucide-react';

const ChatHeader = ({ selectedChat }) => {
  return (
    <div className="p-4 bg-white border-b flex justify-between items-center">
      <div className="flex items-center space-x-3">
        <div className="w-10 h-10 bg-gray-200 rounded-full flex items-center justify-center">
          R
        </div>
        <div>
          <h2 className="font-semibold">Real estate team</h2>
          <p className="text-sm text-gray-500">10 members</p>
        </div>
      </div>
      <Settings className="w-6 h-6 text-gray-500 cursor-pointer" />
    </div>
  );
};

export default ChatHeader;