// src/components/Messenger/MessageInput.js
import React from 'react';
import { Send, Paperclip, Smile } from 'lucide-react';

const MessageInput = ({ messageInput, setMessageInput, onSendMessage }) => {
  return (
    <form onSubmit={onSendMessage} className="p-4 border-t bg-white flex gap-2">
      <div className="flex-1 bg-gray-100 rounded-lg flex items-center p-2">
        <input
          value={messageInput}
          onChange={(e) => setMessageInput(e.target.value)}
          className="flex-1 bg-transparent outline-none px-2"
          placeholder="Write your message..."
        />
        <div className="flex items-center space-x-2 text-gray-500">
          <Paperclip className="w-5 h-5 cursor-pointer" />
          <Smile className="w-5 h-5 cursor-pointer" />
        </div>
      </div>
      <button
        type="submit"
        className="bg-blue-500 text-white p-2 rounded-lg hover:bg-blue-600"
      >
        <Send className="w-5 h-5" />
      </button>
    </form>
  );
};

export default MessageInput;