import React from "react";

const VisionStatement = () => {
  return (
    <section className="min-h-screen flex items-center justify-center bg-gray-1000 text-white px-6 mt-16">
      <div className="bg-gray-900 rounded-lg shadow-xl px-8 py-10 max-w-md sm:max-w-lg border border-gray-700 flex flex-col items-center">
        {/* Logo Section */}
        <div className="mb-6">
  <img
    src="logo3.png"
    alt="DevTrust Logo"
    className="w-10 sm:w-16 object-contain" // Use valid Tailwind width classes
  />
</div>

        {/* Vision Text */}
        <p className="text-lg sm:text-xl leading-relaxed">
          <span className="font-bold text-blue-400">DevTrust</span> is a
          next-generation freelance platform designed to revolutionize project
          management and collaboration. By leveraging blockchain technology for
          secure, milestone-based payments and artificial intelligence (AI) for
          automation,{" "}
          <span className="font-bold text-blue-400">DevTrust</span> ensures a
          transparent, efficient, and equitable workflow for developers and
          project owners alike.
        </p>
        <p className="text-lg sm:text-xl leading-relaxed mt-6">
          At its core, <span className="font-bold text-blue-400">DevTrust</span>{" "}
          integrates cutting-edge tools, trust-based systems, and seamless
          communication channels to foster productivity and accountability.
          Whether managing complex technical projects or tracking milestones in
          real-time, <span className="font-bold text-blue-400">DevTrust</span>{" "}
          eliminates inefficiencies and empowers teams to focus on delivering
          results.
        </p>
        <p className="text-lg sm:text-xl leading-relaxed mt-6">
          <span className="font-bold text-blue-400">DevTrust</span> aspires to
          become the go-to platform for modern freelancing, offering security,
          automation, and trust for developers, project managers, and
          organizations worldwide.
        </p>
      </div>
    </section>
  );
};

export default VisionStatement;
