// src/services/socket.js
import { io } from 'socket.io-client';

const socket = io('http://localhost:5000', {
  auth: {
    token: localStorage.getItem('token'),
  },
  autoConnect: false, // Don't connect automatically
  reconnection: true, // Enable reconnection
  reconnectionAttempts: 5,
  reconnectionDelay: 1000,
});

// Debug listeners
socket.on('connect', () => {
  console.log('✅ Socket connected!', socket.id);
});

socket.on('error', (error) => {
  console.error('❌ Socket error:', error);
});

socket.on('disconnect', () => {
  console.log('⚠️ Socket disconnected');
});

export default socket;