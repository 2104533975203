import React from "react";

const MilestoneTrackerPage = () => {
  return (
    <section className="min-h-screen bg-grey-1000 text-white flex items-center justify-center px-6 py-16">
      <div className="max-w-7xl mx-auto grid grid-cols-1 md:grid-cols-2 gap-12 items-center">
        {/* Left Section */}
        <div>
          <h1 className="text-4xl font-bold mb-6">
            Milestone <br />
            Tracker
          </h1>
          <p className="text-lg text-gray-300 mb-6 leading-relaxed">
            Streamline project execution by managing milestones efficiently.
            Monitor progress, identify delays, and ensure timely completion of
            each phase.
          </p>

          {/* Features */}
          <div className="space-y-4">
            <button className="flex items-center gap-3 bg-gray-800 text-white px-4 py-2 rounded-lg shadow-lg hover:bg-gray-700">
              <span>🗂️</span> Centralized Milestone Management
            </button>
            <button className="flex items-center gap-3 bg-gray-800 text-white px-4 py-2 rounded-lg shadow-lg hover:bg-gray-700">
              <span>📊</span> Detailed Status Updates
            </button>
            <button className="flex items-center gap-3 bg-gray-800 text-white px-4 py-2 rounded-lg shadow-lg hover:bg-gray-700">
              <span>🔍</span> Filters for Quick Access
            </button>
            <button className="flex items-center gap-3 bg-gray-800 text-white px-4 py-2 rounded-lg shadow-lg hover:bg-gray-700">
              <span>📈</span> Visual Gantt Chart Integration
            </button>
          </div>
        </div>

        {/* Right Section */}
        <div className="flex justify-end relative">
          <img
            src="/Milestonespage.png" // Replace with the actual image path
            alt="Milestone Tracker"
            className="w-auto max-w-[100%] md:max-w-[100%] lg:max-w-[100%] h-auto rounded-lg"
            style={{ objectFit: "contain", marginRight: "-13%" }}
          />
        </div>
      </div>
    </section>
  );
};

export default MilestoneTrackerPage;
