import React from "react";
import { motion } from "framer-motion";

const PlannerSection = () => {
  // Animation variants for scroll-in effects
  const containerVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.8 } },
  };

  return (
    <section className="min-h-screen bg-gray-1000 text-white px-6 py-16">
      {/* Heading */}
      <motion.div
        className="text-center mb-12"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.5 }}
        variants={containerVariants}
      >
        <h1 className="text-4xl font-bold">
          Transform your ideas into{" "}
          <span className="text-blue-500">Actionable plan with AI</span>
        </h1>
        <p className="text-lg text-gray-300 mt-4">
          Save time and improve accuracy with automated project roadmaps.
        </p>
      </motion.div>

      {/* Main Content */}
      <div className="max-w-7xl mx-auto grid grid-cols-1 md:grid-cols-2 gap-12 items-center">
        {/* Center Button */}
        <motion.div
          className="flex justify-center md:col-span-2"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.3 }}
          variants={containerVariants}
        >
          <img
            src="Button-CreateBlockchain.png" // Replace with your image path
            alt="Create Blockchain Button"
            className="h-16"
          />
        </motion.div>

        {/* Left Section: Milestones */}
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.3 }}
          variants={containerVariants}
        >
          <img
            src="Milstone-Image.png" // Replace with your image path
            alt="Milestones"
            className="w-full"
          />
        </motion.div>

        {/* Right Section: Planner */}
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.3 }}
          variants={containerVariants}
        >
          <img
            src="Planner-Image.png" // Replace with your image path
            alt="Planner"
            className="w-full"
          />
        </motion.div>
      </div>
    </section>
  );
};

export default PlannerSection;
