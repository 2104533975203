import React from "react";
import { motion } from "framer-motion";

const ProjectRoadmapPage = () => {
  // Animation variants for scroll-in effects
  const containerVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.8 } },
  };

  return (
    <section className="min-h-screen bg-grey-1000 text-white flex items-center justify-center px-6 py-16">
      <div className="max-w-7xl mx-auto grid grid-cols-1 md:grid-cols-2 gap-12 items-center">
        {/* Left Section */}
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          variants={containerVariants}
        >
          <h1 className="text-4xl font-bold mb-6">
            Project <br />
            Roadmap
          </h1>
          <p className="text-lg text-gray-300 mb-6 leading-relaxed">
            Gain complete visibility into your projects with an interactive and
            detailed roadmap. Track progress, milestones, and deadlines at a
            glance.
          </p>

          {/* Features */}
          <div className="space-y-4">
            <motion.button
              className="flex items-center gap-3 bg-gray-800 text-white px-4 py-2 rounded-lg shadow-lg hover:bg-gray-700"
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, amount: 0.5 }}
              variants={containerVariants}
            >
              <span>📁</span> Centralized Project View
            </motion.button>
            <motion.button
              className="flex items-center gap-3 bg-gray-800 text-white px-4 py-2 rounded-lg shadow-lg hover:bg-gray-700"
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, amount: 0.5 }}
              variants={containerVariants}
            >
              <span>⏳</span> Interactive Timeline
            </motion.button>
            <motion.button
              className="flex items-center gap-3 bg-gray-800 text-white px-4 py-2 rounded-lg shadow-lg hover:bg-gray-700"
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, amount: 0.5 }}
              variants={containerVariants}
            >
              <span>🏷️</span> Task Labels & Categories
            </motion.button>
            <motion.button
              className="flex items-center gap-3 bg-gray-800 text-white px-4 py-2 rounded-lg shadow-lg hover:bg-gray-700"
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, amount: 0.5 }}
              variants={containerVariants}
            >
              <span>🔍</span> Filters for Quick Insights
            </motion.button>
          </div>
        </motion.div>

        {/* Right Section */}
        <motion.div
          className="flex justify-end relative"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          variants={containerVariants}
        >
          <img
            src="RoadmapPage.png" // Replace with the actual image path
            alt="Project Roadmap"
            className="w-auto max-w-[90%] md:max-w-[90%] lg:max-w-[90%] h-auto rounded-lg"
            style={{ objectFit: "contain", marginRight: "-13%" }}
          />
        </motion.div>
      </div>
    </section>
  );
};

export default ProjectRoadmapPage;
