import axios from "axios";

// ✅ API Instance Configuration
const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL || 'http://localhost:5000/api', // Fallback for dev
  timeout: 30000, // 30 seconds timeout
  headers: {
    'Content-Type': 'application/json',
  },
});



// Automatically attach the token if available in localStorage
api.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

// Fetch paginated projects
export const fetchProjects = async (page, limit) => {
  const response = await api.get(`/projects?page=${page}&limit=${limit}`);
  return response.data;
};


// Add a response interceptor to handle token expiry or invalidation
api.interceptors.response.use(
  (response) => response, // Return response if successful
  (error) => {
    if (error.response?.status === 401 || error.response?.status === 403) {
      // Clear the token from storage
      localStorage.removeItem('token');

      // Redirect to the sign-in page
      window.location.href = '/login?expired=true'; // Optionally add a query parameter for messaging
    }
    return Promise.reject(error); // Allow further error handling if needed
  }
);

export default api;