import React, { useState } from 'react';
import { useLocation, NavLink, Outlet, useNavigate } from 'react-router-dom';
import {
  Shield,
  Code,
  Briefcase,
  Zap,
  Calendar,
  CreditCard,
  PieChart,
  ChevronLeft,
  ChevronRight,
  LogOut,
  Sun,
  Moon,
  User,
  Users,
  MessageSquare,
} from 'lucide-react';
import { useAuth } from '../context/AuthContext'; // Import the useAuth hook

const Layout = () => {
  const [isExpanded, setIsExpanded] = useState(
    localStorage.getItem('sidebarExpanded') === 'true'
  );
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [userRole, setUserRole] = useState('developer');
  const [roleChanged, setRoleChanged] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { logout } = useAuth(); // Get the logout function from the AuthContext

  const toggleSidebar = () => {
    const newExpandedState = !isExpanded;
    setIsExpanded(newExpandedState);
    localStorage.setItem('sidebarExpanded', newExpandedState.toString());
  };

  const toggleRole = () => {
    const newRole = userRole === 'developer' ? 'owner' : 'developer';
    setUserRole(newRole);
    setRoleChanged(true);

    if (newRole === 'developer') {
      navigate('/projects');
    } else {
      navigate('/projects/owner');
    }

    setTimeout(() => {
      setRoleChanged(false);
    }, 3000);
  };

  const toggleDarkMode = () => {
    setIsDarkMode(!isDarkMode);
  };

  const navLinks = {
    developer: [
      { path: '/projects', icon: <Briefcase />, label: 'Available Projects' },
      { path: '/proof-of-work', icon: <Zap />, label: 'Proof of Work' },
      { path: '/calendar', icon: <Calendar />, label: 'Calendar' },
      { path: '/analytics', icon: <PieChart />, label: 'Analytics' },
      { path: '/messenger', icon: <MessageSquare />, label: 'Messenger' },
    ],
    owner: [
      { path: '/projects/owner', icon: <Briefcase />, label: 'My Projects' },
      { path: '/milestones', icon: <Zap />, label: 'Milestones' },
      { path: '/calendar', icon: <Calendar />, label: 'Calendar' },
      { path: '/payroll', icon: <CreditCard />, label: 'Payroll' },
      { path: '/analytics', icon: <PieChart />, label: 'Analytics' },
      { path: '/messenger', icon: <MessageSquare />, label: 'Messenger' },
    ],
  };

  const getThemeColors = () => {
    return isDarkMode
      ? {
          bg: 'bg-gray-900',
          text: 'text-gray-100',
          border: 'border-gray-800',
          hover: 'hover:bg-gray-800',
          active: 'bg-gray-800',
        }
      : {
          bg: 'bg-white',
          text: 'text-gray-900',
          border: 'border-gray-200',
          hover: 'hover:bg-gray-100',
          active: 'bg-gray-100',
        };
  };

  const colors = getThemeColors();

  const handleLogout = () => {
    logout(); // Clear the user from the context
    navigate('/login'); // Redirect to the login page
  };

  return (
    <div className={`flex h-screen ${isDarkMode ? 'bg-gray-900 text-gray-100' : 'bg-gray-50 text-gray-900'}`}>
      {/* Sidebar */}
      <aside
        className={`${
          isExpanded ? 'w-64' : 'w-20'
        } p-4 border-r flex flex-col justify-between transition-all duration-300 ${colors.bg} ${colors.border}`}
      >
        {/* Sidebar Header */}
        <div className="flex flex-col items-center space-y-4 mb-8">
          <div className="flex items-center justify-between w-full">
            <div className="flex items-center space-x-2">
              <Shield className="w-8 h-8 text-blue-500" />
              {isExpanded && <span className="text-xl font-bold">DevTrust</span>}
            </div>
            <button
              onClick={toggleSidebar}
              className="text-gray-400 hover:text-blue-500"
              title={isExpanded ? 'Collapse Sidebar' : 'Expand Sidebar'}
            >
              {isExpanded ? <ChevronLeft /> : <ChevronRight />}
            </button>
          </div>
          {isExpanded && (
            <button
              onClick={toggleRole}
              className={`w-full py-2 px-4 rounded-lg transition-colors duration-200 bg-blue-500 hover:bg-blue-600 text-white`}
            >
              Switch to {userRole === 'developer' ? 'Owner' : 'Developer'}
            </button>
          )}
        </div>

        {/* Navigation Links */}
        <div className="space-y-4 flex-grow">
          {navLinks[userRole].map(({ path, icon, label }) => (
            <NavLink
              key={path}
              to={path}
              className={({ isActive }) =>
                `flex items-center p-3 rounded-lg transition ${
                  isActive
                    ? 'bg-blue-500 text-white'
                    : `${colors.text} ${colors.hover}`
                }`
              }
              title={label}
            >
              <span className="w-5 h-5 mr-2">{icon}</span>
              {isExpanded && label}
            </NavLink>
          ))}
        </div>

        {/* Sidebar Footer */}
        <div className="space-y-4">
          {!isExpanded && (
            <button
              onClick={toggleRole}
              className={`flex items-center justify-center w-full p-3 rounded-lg transition bg-blue-500 text-white hover:bg-blue-600`}
              title={`Switch to ${userRole === 'developer' ? 'Owner' : 'Developer'}`}
            >
              {userRole === 'developer' ? <Code className="w-5 h-5" /> : <Users className="w-5 h-5" />}
            </button>
          )}
          <button
            onClick={toggleDarkMode}
            className={`flex items-center justify-center w-full p-3 rounded-lg transition ${
              isDarkMode ? 'bg-yellow-500 text-gray-900' : 'bg-gray-200 text-gray-700'
            } hover:opacity-80`}
            title={isDarkMode ? 'Switch to Light Mode' : 'Switch to Dark Mode'}
          >
            {isDarkMode ? <Sun className="w-5 h-5" /> : <Moon className="w-5 h-5" />}
          </button>
          <button
            onClick={() => navigate('/profile')}
            className={`flex items-center w-full p-3 rounded-lg transition ${colors.hover}`}
            title="Profile"
          >
            <User className="w-5 h-5 mr-2" />
            {isExpanded && 'Profile'}
          </button>
          <button
            onClick={handleLogout}
            className={`flex items-center w-full p-3 rounded-lg transition ${colors.hover}`}
            title="Logout"
          >
            <LogOut className="w-5 h-5 mr-2" />
            {isExpanded && 'Logout'}
          </button>
        </div>
      </aside>

      {/* Main Content */}
      <main className={`flex-1 overflow-y-auto relative ${location.pathname === '/messenger' ? '' : 'p-6'}`}>
        <Outlet />
      </main>
    </div>
  );
};

export default Layout;
