import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import api from '../../services/api';
import { useAuth } from '../../context/AuthContext';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const navigate = useNavigate();
  const { login } = useAuth();

  const handleLogin = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError('');
  
    if (!email || !password) {
      setError('Email and password are required.');
      setIsSubmitting(false);
      return;
    }
  
    try {
      console.log('Attempting login...');
      const response = await fetch('http://localhost:5000/api/auth/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email,
          password,
        }),
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const data = await response.json();
      console.log('API Response:', data);
  
      const { userId, token, isAdmin } = data;
  
      if (!userId || !token) {
        throw new Error('Missing userId or token in the response.');
      }
  
      // Safely handle isAdmin
      const isAdminFlag = isAdmin || false; // Default to false if undefined
      localStorage.setItem('userId', userId);
      localStorage.setItem('token', token);
      localStorage.setItem('isAdmin', isAdminFlag.toString());
  
      login(email, userId, token, isAdminFlag);
  
      navigate(isAdminFlag ? '/admin' : '/dashboard');
    } catch (err) {
      console.error('Login error:', err.message || err);
      setError(err.message || 'An unexpected error occurred. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };
  

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="w-full max-w-md px-6">
        <form onSubmit={handleLogin} className="bg-white p-8 rounded-lg shadow-lg">
          <h2 className="text-2xl font-bold text-center mb-4">Login</h2>
          {error && (
            <div className="mb-4 text-red-500 flex items-center">
              {error}
            </div>
          )}
          <div className="mb-4">
            <label className="block text-gray-700">Email</label>
            <input
              type="email"
              className="w-full px-4 py-2 border rounded-md"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700">Password</label>
            <input
              type="password"
              className="w-full px-4 py-2 border rounded-md"
              placeholder="Enter your password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <button
            type="submit"
            disabled={isSubmitting}
            className="w-full bg-blue-600 text-white py-2 rounded-md"
          >
            {isSubmitting ? 'Logging in...' : 'Login'}
          </button>
          <p className="text-sm mt-4 text-center">
            Don't have an account?{' '}
            <Link to="/signup" className="text-blue-600">
              Sign Up
            </Link>
          </p>
        </form>
      </div>
    </div>
  );
};

export default Login;
