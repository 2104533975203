import React, { useState } from "react";
import { motion } from "framer-motion";

const FAQSection = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const faqs = [
    { question: "What is Team DevTrust?", answer: "Team DevTrust helps blockchain projects succeed with collaborative tools." },
    { question: "How does the escrow system work?", answer: "Funds are held securely until milestones are approved by both parties." },
    { question: "How does the technology work?", answer: "We integrate AI-powered project management tools and secure blockchain solutions." },
    { question: "What happens if a milestone is disputed?", answer: "A resolution process involving mediation ensures fair outcomes." },
    { question: "How do I find projects matching my skills?", answer: "Use our skill-matching algorithm to find relevant projects." },
  ];

  const toggleFAQ = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  return (
    <section className="bg-gray-1000 text-white py-16 px-6">
      <div className="max-w-3xl mx-auto">
        
        {/* Title with Scroll Animation */}
        <motion.div 
          initial={{ opacity: 0, y: 30 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          viewport={{ once: true }}
          className="text-center mb-10"
        >
          <h2 className="text-4xl font-bold">Frequently Asked Questions</h2>
          <p className="mt-3 text-gray-300">
            We’re happy to answer your questions.
          </p>
        </motion.div>

        {/* FAQ List */}
        <div className="space-y-4">
          {faqs.map((faq, index) => (
            <motion.div
              key={index}
              className="border-b border-gray-700"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
              viewport={{ once: true }}
            >
              <button
                className="w-full text-left flex justify-between items-center py-4 text-lg font-semibold focus:outline-none"
                onClick={() => toggleFAQ(index)}
              >
                {faq.question}
                <span
                  className={`transition-transform transform ${
                    activeIndex === index ? "rotate-45" : "rotate-0"
                  }`}
                >
                  {activeIndex === index ? "−" : "+"}
                </span>
              </button>

              {/* Animated Answer Section */}
              <motion.div
                initial={{ height: 0, opacity: 0 }}
                animate={{
                  height: activeIndex === index ? "auto" : 0,
                  opacity: activeIndex === index ? 1 : 0,
                }}
                transition={{ duration: 0.3, ease: "easeInOut" }}
                className="overflow-hidden text-gray-300"
              >
                <p className="pb-4">{faq.answer}</p>
              </motion.div>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default FAQSection;
