import React, { useState } from 'react';
import { CheckCircle, Activity, X } from 'lucide-react';

const ProofOfWork = () => {
  const [isDarkMode] = useState(false);
  const [submissions] = useState([
    { id: 1, project: 'Project Alpha', link: 'http://github.com/project-alpha', date: '2023-12-01', status: 'Approved' },
    { id: 2, project: 'Project Beta', link: 'http://github.com/project-beta', date: '2023-12-05', status: 'Pending' },
    { id: 3, project: 'Project Gamma', link: 'http://github.com/project-gamma', date: '2023-12-10', status: 'Rejected' },
  ]);

  return (
    <div>
      <h1 className="text-2xl font-semibold mb-6">Proof of Work</h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
        {submissions.map((submission) => (
          <div
            key={submission.id}
            className={`p-4 rounded-lg border ${
              isDarkMode
                ? 'bg-gray-800 border-gray-700 hover:bg-gray-700'
                : 'bg-white border-gray-200 hover:shadow-md'
            }`}
          >
            <div className="flex items-center justify-between mb-2">
              <h2 className="text-lg font-medium">{submission.project}</h2>
              {submission.status === 'Approved' && (
                <div className="flex items-center space-x-1 text-green-500">
                  <CheckCircle className="w-4 h-4" />
                  <span className="text-sm">Approved</span>
                </div>
              )}
              {submission.status === 'Pending' && (
                <div className="flex items-center space-x-1 text-yellow-500">
                  <Activity className="w-4 h-4" />
                  <span className="text-sm">Pending</span>
                </div>
              )}
              {submission.status === 'Rejected' && (
                <div className="flex items-center space-x-1 text-red-500">
                  <X className="w-4 h-4" />
                  <span className="text-sm">Rejected</span>
                </div>
              )}
            </div>
            <a
              href={submission.link}
              target="_blank"
              rel="noopener noreferrer"
              className={`block text-sm ${isDarkMode ? 'text-blue-400' : 'text-blue-500'} hover:underline`}
            >
              View Submission
            </a>
            <p className="text-sm text-gray-500">Submitted on: {submission.date}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProofOfWork;
