import React from "react";
import { motion } from "framer-motion";

const RoadmapPage = () => {
  // Animation variants for scroll-in effects
  const containerVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.8 } },
  };

  return (
    <section className="min-h-screen bg-gray-1000 text-white px-6 py-16">
      {/* Title */}
      <motion.div
        className="text-center mb-12"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.5 }}
        variants={containerVariants}
      >
        <h1 className="text-4xl font-bold">
          Development <span className="text-blue-500">Roadmap</span>
        </h1>
      </motion.div>

      {/* Roadmap Image */}
      <motion.div
        className="flex justify-center"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.5 }}
        variants={containerVariants}
      >
        <img
          src="roadmap.png" // Replace with your roadmap image file path
          alt="Development Roadmap"
          className="w-full max-w-4xl"
        />
      </motion.div>
    </section>
  );
};

export default RoadmapPage;
