import React from "react";

const FeaturesSection = () => {
  const features = [
    {
      title: "AI-Driven Project Creation",
      description:
        "Simplify project setup with AI-powered tools that automatically generate project structures, milestones, and budgets based on user inputs and industry best practices.",
      icon: "AI-Icon.png", // Replace with the path to your PNG
    },
    {
      title: "Secure Escrow Payments",
      description:
        "Blockchain-based escrow ensures funds are released only after milestone completion, fostering trust and accountability between developers and project owners.",
      icon: "Dev-Icon.png", // Replace with the path to your PNG
    },
    {
      title: "Advanced Developer Tools",
      description:
        "Access controlled bidding systems, milestone-specific notifications, and a reputation-based review system to highlight high-performing contributors.",
      icon: "Job-Icon.png", // Replace with the path to your PNG
    },
    {
      title: "Milestone Dependencies",
      description:
        "Plan and manage dependencies across milestones to ensure projects stay on track with clearly defined goals and timelines.",
      icon: "Milestone-Icon.png", // Replace with the path to your PNG
    },
    {
      title: "Analytics & Insights",
      description:
        "Gain actionable insights into project performance, milestone completion rates, and team efficiency through intuitive dashboards.",
      icon: "Analytics-Icon.png", // Replace with the path to your PNG
    },
    {
      title: "API for External Project Creation",
      description:
        "Enable users to create, manage, and sync projects from external systems or tools using a robust API, ensuring seamless integration with DevTrust.",
      icon: "API-Icon.png", // Replace with the path to your PNG
    },
  ];

  return (
    <section className="min-h-screen bg-gray-1000 text-white py-16 px-6">
      <div className="text-center mb-12">
        <h2 className="text-4xl font-bold">
          Key <span className="text-blue-500">Features</span>
        </h2>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 max-w-7xl mx-auto">
        {features.map((feature, index) => (
          <div
            key={index}
            className="bg-gray-900 p-6 rounded-lg shadow-lg border border-gray-700 flex flex-col items-center text-center"
          >
            <div className="mb-4">
              <img
                src={feature.icon}
                alt={feature.title}
                className="w-16 h-16 object-contain"
              />
            </div>
            <h3 className="text-xl font-semibold mb-4">{feature.title}</h3>
            <p className="text-gray-300">{feature.description}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default FeaturesSection;
