import React, { useState } from 'react';
import { Briefcase, CheckCircle, XCircle } from 'lucide-react';

const Payroll = () => {
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [payments, setPayments] = useState([
    { id: 1, developer: 'John Doe', project: 'Project Alpha', milestone: 'Milestone 1', amount: '500 USDT', status: 'Completed', date: '2024-01-05' },
    { id: 2, developer: 'Jane Smith', project: 'Project Beta', milestone: 'Milestone 3', amount: '1000 ETH', status: 'Pending', date: '2024-01-15' },
    { id: 3, developer: 'Alex Johnson', project: 'Project Gamma', milestone: 'Milestone 2', amount: '300 BTC', status: 'Failed', date: '2024-01-10' },
  ]);

  return (
    <div className={`p-6 min-h-screen ${isDarkMode ? 'bg-[#0B1120] text-gray-100' : 'bg-gray-50 text-gray-900'}`}>
      {/* Toggle Theme Button */}
      <div className="flex justify-end mb-4">
        <button
          onClick={() => setIsDarkMode((prev) => !prev)}
          className={`px-4 py-2 rounded-md ${isDarkMode ? 'bg-gray-700 text-gray-200' : 'bg-blue-500 text-white'}`}
        >
          {isDarkMode ? 'Switch to Light Mode' : 'Switch to Dark Mode'}
        </button>
      </div>

      {/* Overview Section */}
      <div className="grid grid-cols-3 gap-6 mb-6">
        <div className={`p-4 rounded-lg ${isDarkMode ? 'bg-gray-800' : 'bg-white'} shadow`}>
          <h2 className="text-lg font-medium mb-2">Pending Payments</h2>
          <p className="text-3xl font-bold">5</p>
        </div>
        <div className={`p-4 rounded-lg ${isDarkMode ? 'bg-gray-800' : 'bg-white'} shadow`}>
          <h2 className="text-lg font-medium mb-2">Total Paid</h2>
          <p className="text-3xl font-bold">$25,000</p>
        </div>
        <div className={`p-4 rounded-lg ${isDarkMode ? 'bg-gray-800' : 'bg-white'} shadow`}>
          <h2 className="text-lg font-medium mb-2">Upcoming Payments</h2>
          <p className="text-3xl font-bold">$10,000</p>
        </div>
      </div>

      {/* Send Payment Section */}
      <div className={`p-4 rounded-lg ${isDarkMode ? 'bg-gray-800' : 'bg-white'} shadow mb-6`}>
        <h2 className="text-lg font-semibold mb-4">Send Payment</h2>
        <form className="grid grid-cols-2 gap-4">
          <div>
            <label className="block text-sm mb-2">Developer</label>
            <input
              type="text"
              placeholder="Enter developer name"
              className={`w-full px-4 py-2 rounded-lg ${
                isDarkMode ? 'bg-gray-700 text-gray-100' : 'bg-gray-50 text-gray-900'
              }`}
            />
          </div>
          <div>
            <label className="block text-sm mb-2">Project</label>
            <input
              type="text"
              placeholder="Enter project name"
              className={`w-full px-4 py-2 rounded-lg ${
                isDarkMode ? 'bg-gray-700 text-gray-100' : 'bg-gray-50 text-gray-900'
              }`}
            />
          </div>
          <div>
            <label className="block text-sm mb-2">Milestone</label>
            <input
              type="text"
              placeholder="Enter milestone"
              className={`w-full px-4 py-2 rounded-lg ${
                isDarkMode ? 'bg-gray-700 text-gray-100' : 'bg-gray-50 text-gray-900'
              }`}
            />
          </div>
          <div>
            <label className="block text-sm mb-2">Amount</label>
            <input
              type="text"
              placeholder="Enter amount"
              className={`w-full px-4 py-2 rounded-lg ${
                isDarkMode ? 'bg-gray-700 text-gray-100' : 'bg-gray-50 text-gray-900'
              }`}
            />
          </div>
          <button
            type="submit"
            className="col-span-2 py-2 px-4 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
          >
            Send Payment
          </button>
        </form>
      </div>

      {/* Payment History Section */}
      <div className={`p-4 rounded-lg ${isDarkMode ? 'bg-gray-800' : 'bg-white'} shadow`}>
        <h2 className="text-lg font-semibold mb-4">Payment History</h2>
        <table className="w-full text-sm border-collapse border border-gray-200">
          <thead>
            <tr className="bg-gray-100 text-left border-b">
              <th className="py-2 px-4">Developer</th>
              <th className="py-2 px-4">Project</th>
              <th className="py-2 px-4">Milestone</th>
              <th className="py-2 px-4">Amount</th>
              <th className="py-2 px-4">Status</th>
              <th className="py-2 px-4">Date</th>
            </tr>
          </thead>
          <tbody>
            {payments.map((payment) => (
              <tr key={payment.id} className="border-b">
                <td className="py-2 px-4">{payment.developer}</td>
                <td className="py-2 px-4">{payment.project}</td>
                <td className="py-2 px-4">{payment.milestone}</td>
                <td className="py-2 px-4">{payment.amount}</td>
                <td
                  className={`py-2 px-4 ${
                    payment.status === 'Completed'
                      ? 'text-green-500'
                      : payment.status === 'Pending'
                      ? 'text-yellow-500'
                      : 'text-red-500'
                  }`}
                >
                  {payment.status}
                </td>
                <td className="py-2 px-4">{payment.date}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Payroll;
