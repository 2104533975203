import React from 'react';
import { FaTwitter, FaTelegramPlane, FaTiktok } from 'react-icons/fa';

const Footer = () => {
  return (
    <footer id="contact-us" className="bg-gray-1000 text-white py-8">
      <div className="container mx-auto px-4">
        {/* Social Media Links */}
        <div className="flex justify-center space-x-6 mb-6">
          <a
            href="https://x.com/DevTrust_IO"
            target="_blank"
            rel="noopener noreferrer"
            className="text-white hover:text-blue-400 transition"
          >
            <FaTwitter size={24} />
          </a>
          <a
            href="httphttps://t.me/+cSrG-PMImjlmNDFk"
            target="_blank"
            rel="noopener noreferrer"
            className="text-white hover:text-blue-500 transition"
          >
            <FaTelegramPlane size={24} />
          </a>
          <a
            href="https://www.tiktok.com"
            target="_blank"
            rel="noopener noreferrer"
            className="text-white hover:text-black transition"
          >
            <FaTiktok size={24} />
          </a>
        </div>

        {/* Footer Navigation */}
        <div className="flex justify-center space-x-6 mb-6">
          <a href="#hero-section" className="hover:underline">
            Home
          </a>
          <a href="#why-devtrust" className="hover:underline">
            Vision
          </a>
          <a href="#how-devtrust-works" className="hover:underline">
            Features
          </a>
          <a href="#roadmap" className="hover:underline">
            Roadmap
          </a>
        </div>

        {/* Footer Copyright */}
        <div className="text-center text-sm">
          © {new Date().getFullYear()} DevTrust - All rights reserved.
        </div>
      </div>
    </footer>
  );
};

export default Footer;
