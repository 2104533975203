// src/components/PrivateRoute.js
import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { jwtDecode } from 'jwt-decode';


const PrivateRoute = ({ children }) => {
  const { user, logout } = useAuth();

  useEffect(() => {
    if (user?.token) {
      try {
        const decodedToken = jwtDecode(user.token);

        // Check if the token is expired
        if (decodedToken.exp * 1000 < Date.now()) {
          logout(); // Log the user out if the token is expired
        }
      } catch (err) {
        console.error('Token verification error:', err);
        logout(); // Log the user out if the token is invalid
      }
    }
  }, [user, logout]);

  return user ? children : <Navigate to="/login" replace />;
};

export default PrivateRoute;
