import React, { useState, useEffect } from 'react';
import { useAuth } from '../../context/AuthContext';
import { Upload, X, Camera } from 'lucide-react';
import api from '../../services/api';

const availableSkills = [
  'React', 'Node.js', 'Blockchain', 'JavaScript', 'Python',
  'Docker', 'Kubernetes', 'SQL', 'Rust', 'Solidity'
];

function Profile() {
  const { user, logout } = useAuth();
  const [isEditing, setIsEditing] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  
  const [profileData, setProfileData] = useState({
    name: '',
    email: '',
    bio: '',
    profilePicture: '',
    profilePicturePreview: null,
    skills: [],
  });

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await api.get('/users/profile');
        if (response.data) {
          setProfileData({
            name: response.data.name || '',
            email: response.data.email || '',
            bio: response.data.bio || '',
            profilePicture: response.data.profilePicture || '/profilePictures/default/randomPFP1.webp',
            skills: response.data.skills || [],
          });
        }
      } catch (error) {
        setError('Failed to load profile data');
        console.error('Error loading profile:', error);
      }
    };
    fetchProfile();
  }, []);

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > 5 * 1024 * 1024) {
        setError('Image size should be less than 5MB');
        return;
      }

      const imageUrl = URL.createObjectURL(file);
      setProfileData(prev => ({
        ...prev,
        profilePicture: file,
        profilePicturePreview: imageUrl,
      }));
      setError('');
    }
  };

  const handleSkillChange = (e) => {
    const skill = e.target.value;
    if (skill && !profileData.skills.includes(skill)) {
      setProfileData(prev => ({
        ...prev,
        skills: [...prev.skills, skill],
      }));
      e.target.value = ''; // Reset select after adding
    }
  };

  const removeSkill = (skillToRemove) => {
    setProfileData(prev => ({
      ...prev,
      skills: prev.skills.filter(skill => skill !== skillToRemove),
    }));
  };

  const saveProfile = async () => {
    try {
      setError('');
      setIsUploading(true);
      
      const formData = new FormData();
      formData.append('name', profileData.name);
      formData.append('bio', profileData.bio);
      formData.append('skills', JSON.stringify(profileData.skills));
      
      if (profileData.profilePicture instanceof File) {
        formData.append('profilePicture', profileData.profilePicture);
      }

      const response = await api.put('/users/profile', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      if (response.status === 200) {
        setSuccess('Profile updated successfully!');
        setProfileData(prev => ({
          ...prev,
          profilePicture: response.data.profilePicture,
          profilePicturePreview: null,
          skills: response.data.skills || prev.skills,
        }));
        setIsEditing(false);
        setTimeout(() => setSuccess(''), 3000); // Clear success message after 3s
      }
    } catch (error) {
      setError(error.response?.data?.error || 'Failed to update profile');
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <div className="max-w-4xl mx-auto p-4">
      <div className="bg-white rounded-lg shadow-md overflow-hidden">
        {/* Header */}
        <div className="p-6 border-b border-gray-200">
          <h1 className="text-2xl font-bold text-gray-800">Profile Settings</h1>
        </div>

        {/* Alert Messages */}
        {error && (
          <div className="p-4 mb-4 bg-red-50 border-l-4 border-red-500 text-red-700">
            {error}
          </div>
        )}
        {success && (
          <div className="p-4 mb-4 bg-green-50 border-l-4 border-green-500 text-green-700">
            {success}
          </div>
        )}

        <div className="p-6 space-y-6">
          {/* Profile Picture Section */}
          <div className="flex items-center space-x-6">
            <div className="relative">
              <img
                src={profileData.profilePicturePreview || profileData.profilePicture}
                alt="Profile"
                className="w-32 h-32 rounded-full object-cover border-4 border-gray-200"
              />
              {isEditing && (
                <label className="absolute bottom-0 right-0 bg-blue-500 text-white p-2 rounded-full cursor-pointer hover:bg-blue-600">
                  <Camera className="w-4 h-4" />
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleFileUpload}
                    className="hidden"
                    disabled={isUploading}
                  />
                </label>
              )}
            </div>
            
            <div className="flex-1 space-y-4">
              {isEditing ? (
                <input
                  type="text"
                  value={profileData.name}
                  onChange={(e) => setProfileData(prev => ({ ...prev, name: e.target.value }))}
                  placeholder="Your Name"
                  className="w-full px-3 py-2 border border-gray-300 rounded-md text-lg font-semibold"
                />
              ) : (
                <h2 className="text-xl font-semibold">{profileData.name}</h2>
              )}
              <p className="text-gray-500">{profileData.email}</p>
            </div>
          </div>

          {/* Bio Section */}
          <div className="space-y-2">
            <h3 className="text-lg font-medium text-gray-800">Bio</h3>
            {isEditing ? (
              <textarea
                value={profileData.bio}
                onChange={(e) => setProfileData(prev => ({ ...prev, bio: e.target.value }))}
                placeholder="Tell us about yourself"
                rows={4}
                className="w-full px-3 py-2 border border-gray-300 rounded-md"
              />
            ) : (
              <p className="text-gray-600">{profileData.bio}</p>
            )}
          </div>

          {/* Skills Section */}
          <div className="space-y-2">
            <h3 className="text-lg font-medium text-gray-800">Skills</h3>
            <div className="flex flex-wrap gap-2">
              {profileData.skills.map((skill) => (
                <span 
                  key={skill}
                  className="inline-flex items-center px-3 py-1 rounded-full text-sm bg-blue-100 text-blue-800"
                >
                  {skill}
                  {isEditing && (
                    <button
                      onClick={() => removeSkill(skill)}
                      className="ml-2 text-blue-600 hover:text-blue-800"
                    >
                      <X className="w-3 h-3" />
                    </button>
                  )}
                </span>
              ))}
            </div>
            
            {isEditing && (
              <select
                onChange={handleSkillChange}
                className="mt-2 w-full md:w-64 px-3 py-2 border border-gray-300 rounded-md"
              >
                <option value="">Add a skill</option>
                {availableSkills
                  .filter(skill => !profileData.skills.includes(skill))
                  .map(skill => (
                    <option key={skill} value={skill}>
                      {skill}
                    </option>
                  ))}
              </select>
            )}
          </div>

          {/* Action Buttons */}
          <div className="flex justify-between pt-6">
            <button
              onClick={logout}
              className="px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600 transition-colors"
            >
              Logout
            </button>
            <button
              onClick={isEditing ? saveProfile : () => setIsEditing(true)}
              disabled={isUploading}
              className={`px-4 py-2 rounded-md transition-colors ${
                isEditing
                  ? 'bg-green-500 hover:bg-green-600 text-white'
                  : 'bg-blue-500 hover:bg-blue-600 text-white'
              } ${isUploading ? 'opacity-50 cursor-not-allowed' : ''}`}
            >
              {isEditing ? (isUploading ? 'Saving...' : 'Save Changes') : 'Edit Profile'}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Profile;