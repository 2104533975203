import React, { useState, useEffect } from 'react';
import { FaArrowLeft, FaArrowRight, FaStar, FaCheckCircle, FaClock, FaDollarSign } from 'react-icons/fa';
import api from '../../services/api';
import { Link } from 'react-router-dom';

const ProjectsPage = () => {
  const [activeTab, setActiveTab] = useState('recommended');
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [pagination, setPagination] = useState({ currentPage: 1, totalPages: 1 });
  const [filters, setFilters] = useState({
    category: '',
    budgetMin: '',
    budgetMax: '',
    duration: '',
    skills: [],
    sortBy: 'newest',
  });

  // 🛠️ Fetch Projects
  const fetchProjects = async (page = 1) => {
    setLoading(true);
    setError('');
    try {
      const { category, budgetMin, budgetMax, skills, sortBy } = filters;
      const response = await api.get('/projects/available', {
        params: { 
          page, 
          category, 
          budgetMin, 
          budgetMax, 
          skills: skills.join(','), 
          sortBy 
        },
      });
      setProjects(response.data.projects || []);
      setPagination({ 
        currentPage: response.data.currentPage || 1, 
        totalPages: response.data.totalPages || 1 
      });
    } catch (err) {
      console.error('Error fetching projects:', err.response?.data || err.message);
      setError('Failed to load projects. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  // Fetch projects on load and when filters change
  useEffect(() => {
    fetchProjects();
  }, [filters, activeTab]);

  // 🛠️ Handle Filter Changes
  const handleFilterChange = (e) => {
    const { name, value, type, checked } = e.target;
    
    if (type === 'checkbox') {
      setFilters(prev => ({
        ...prev,
        skills: checked 
          ? [...prev.skills, value]
          : prev.skills.filter(skill => skill !== value)
      }));
    } else if (name === 'budgetMin' || name === 'budgetMax') {
      const numericValue = value === '' ? '' : Number(value);
      setFilters(prev => ({
        ...prev,
        [name]: numericValue
      }));
    } else {
      setFilters(prev => ({
        ...prev,
        [name]: value
      }));
    }
  };

  // 🛠️ Handle Pagination
  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= pagination.totalPages) {
      fetchProjects(newPage);
    }
  };

  return (
    <div className="max-w-7xl mx-auto p-6">
      {/* Stats Cards */}
      <div className="grid md:grid-cols-4 gap-6 mb-8">
        {/* Match Rate */}
        <div className="bg-gradient-to-br from-blue-50 to-blue-100 p-6 rounded-xl shadow-sm">
          <div className="flex items-center gap-3 mb-2">
            <div className="p-2 bg-blue-500 rounded-lg">
              <FaStar className="text-white" size={20} />
            </div>
            <h4 className="text-blue-900">Match Rate</h4>
          </div>
          <p className="text-3xl font-bold text-blue-700">92%</p>
        </div>

        {/* Available Projects */}
        <div className="bg-gradient-to-br from-green-50 to-green-100 p-6 rounded-xl shadow-sm">
          <div className="flex items-center gap-3 mb-2">
            <div className="p-2 bg-green-500 rounded-lg">
              <FaClock className="text-white" size={20} />
            </div>
            <h4 className="text-green-900">Available Projects</h4>
          </div>
          <p className="text-3xl font-bold text-green-700">24</p>
        </div>
      </div>

      {/* Main Content */}
      <div className="flex gap-8">
        {/* Filters Sidebar */}
        <div className="w-80 flex-shrink-0">
          <div className="bg-white rounded-xl shadow-sm border border-gray-200 p-6">
            <h3 className="text-lg font-semibold mb-6">Filters</h3>
            <div className="space-y-6">
              {/* Budget Range */}
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">Budget Range</label>
                <div className="grid grid-cols-2 gap-2">
                  <input
                    type="number"
                    name="budgetMin"
                    placeholder="Min Budget"
                    value={filters.budgetMin}
                    onChange={handleFilterChange}
                    className="w-full px-3 py-2 border rounded-lg"
                  />
                  <input
                    type="number"
                    name="budgetMax"
                    placeholder="Max Budget"
                    value={filters.budgetMax}
                    onChange={handleFilterChange}
                    className="w-full px-3 py-2 border rounded-lg"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Projects Grid */}
        <div className="flex-1">
          {loading ? (
            <div className="flex justify-center items-center h-64">
              <div className="animate-spin rounded-full h-12 w-12 border-4 border-blue-500 border-t-transparent"></div>
            </div>
          ) : error ? (
            <div className="bg-red-50 text-red-700 p-4 rounded-lg">{error}</div>
          ) : (
            <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
{projects.map((project) => {
  const projectId = project.id || project._id;
  if (!projectId) {
    console.error('Missing project ID:', project);
    return null;
  }

  return (
    <Link 
      key={projectId} 
      to={`/projects/${projectId}`} 
      className="block hover:shadow-lg transition-shadow"
    >
      <div className="bg-white rounded-xl shadow-sm border border-gray-200 hover:border-blue-500 transition-colors p-6">
        {/* Project Title */}
        <h3 className="text-lg font-semibold text-gray-900">{project.title}</h3>
        
        {/* Project Description */}
        <p className="text-gray-600 mb-4">{project.description}</p>

        {/* Project Stats */}
        <div className="grid grid-cols-2 gap-4 mb-4">
          {/* Budget */}
          <div className="flex items-center gap-2">
            <FaDollarSign className="text-gray-400" />
            <div>
              <p className="text-sm text-gray-500">Budget</p>
              <p className="font-medium">
                {project.budget?.estimate 
                  ? `$${project.budget.estimate}` 
                  : 'N/A'}
              </p>
            </div>
          </div>

          {/* Duration */}
          <div className="flex items-center gap-2">
            <FaClock className="text-gray-400" />
            <div>
              <p className="text-sm text-gray-500">Duration</p>
              <p className="font-medium">{project.duration || '3 months'}</p>
            </div>
          </div>
        </div>

        {/* Skills Tags */}
        <div className="flex flex-wrap gap-2">
          {(project.skills || ['React', 'TypeScript']).map((skill) => (
            <span
              key={skill}
              className="px-2 py-1 bg-blue-50 text-blue-700 rounded-lg text-sm"
            >
              {skill}
            </span>
          ))}
        </div>
      </div>
    </Link>
  );
})}

            </div>
          )}

          {/* Pagination */}
          <div className="flex justify-between items-center mt-8">
            <button onClick={() => handlePageChange(pagination.currentPage - 1)}>
              <FaArrowLeft /> Previous
            </button>
            <span>Page {pagination.currentPage} of {pagination.totalPages}</span>
            <button onClick={() => handlePageChange(pagination.currentPage + 1)}>
              Next <FaArrowRight />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectsPage;
