import React, { useState } from 'react';
import { FaPlus, FaTimes, FaTrash, FaEdit } from 'react-icons/fa';

const INITIAL_FORM_STATE = {
  title: 'Test Project Title', // Add default value
  description: 'Test Project Description', // Add default value
  category: 'Test Category', // Add default value
  technicalStack: {},
  coreFeatures: [],
  budget: {
    estimate: '',
    breakdown: {}
  },
  milestones: []
};

const DevPreview = ({ formData }) => (
  <div className="bg-white rounded-lg border">
    <div className="p-6">
      {/* Project Header */}
      <div className="mb-6">
        <h2 className="text-2xl font-bold mb-2">{formData.title}</h2>
        <p className="text-gray-600">{formData.description}</p>
      </div>

      {/* Quick Info */}
      <div className="grid grid-cols-2 gap-4 mb-6">
        <div className="p-4 bg-gray-50 rounded-lg">
          <h3 className="font-medium text-gray-700 mb-2">Timeline</h3>
          <p className="text-gray-600">
            {formData.milestones?.reduce((total, m) => total + (parseInt(m.timeline) || 0), 0)} weeks estimated
          </p>
        </div>
        <div className="p-4 bg-gray-50 rounded-lg">
          <h3 className="font-medium text-gray-700 mb-2">Budget Range</h3>
          <p className="text-gray-600">{formData.budget?.estimate || 'Not specified'}</p>
        </div>
      </div>

      {/* Technical Requirements */}
      <div className="mb-6">
        <h3 className="text-lg font-semibold mb-3">Technical Requirements</h3>
        <div className="grid grid-cols-2 gap-4">
          {Object.entries(formData.technicalStack || {}).map(([category, techs]) => (
            <div key={category} className="p-4 bg-gray-50 rounded-lg">
              <h4 className="font-medium capitalize mb-2">{category}</h4>
              <ul className="list-disc pl-4">
                {techs.map((tech, i) => (
                  <li key={i} className="text-gray-600">{tech}</li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>

      {/* Core Features */}
      <div className="mb-6">
        <h3 className="text-lg font-semibold mb-3">Core Features</h3>
        <div className="space-y-3">
          {formData.coreFeatures?.map((feature, index) => (
            <div key={index} className="p-4 bg-gray-50 rounded-lg">
              <h4 className="font-medium mb-2">{feature.name}</h4>
              <p className="text-gray-600 mb-2">{feature.description}</p>
            </div>
          ))}
        </div>
      </div>

      {/* Development Milestones */}
      <div>
        <h3 className="text-lg font-semibold mb-3">Development Milestones</h3>
        <div className="space-y-3">
          {formData.milestones?.map((milestone, index) => (
            <div key={index} className="p-4 bg-gray-50 rounded-lg flex justify-between">
              <div>
                <h4 className="font-medium">{milestone.title}</h4>
                <p className="text-gray-600">{milestone.description}</p>
                <span className="inline-block mt-2 text-blue-600 text-sm">{milestone.category}</span>
              </div>
              <div className="text-right">
                <span className="inline-block px-3 py-1 bg-blue-100 text-blue-800 rounded">
                  {milestone.timeline}
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  </div>
);

const ProjectReview = ({ formData, setFormData }) => {
  const [activeTab, setActiveTab] = useState('overview');

  const tabs = [
    { id: 'overview', label: 'Overview' },
    { id: 'technical', label: 'Technical Stack' },
    { id: 'features', label: 'Core Features' },
    { id: 'milestones', label: 'Milestones' },
    { id: 'budget', label: 'Budget' },
    { id: 'preview', label: 'Preview' }
  ];

  return (
    <div className="space-y-4">
      <div className="border-b">
        <nav className="flex space-x-4">
          {tabs.map(tab => (
            <button
              key={tab.id}
              onClick={() => setActiveTab(tab.id)}
              className={`py-2 px-4 text-sm font-medium ${
                activeTab === tab.id
                  ? 'border-b-2 border-blue-500 text-blue-600'
                  : 'text-gray-500 hover:text-gray-700'
              }`}
            >
              {tab.label}
            </button>
          ))}
        </nav>
      </div>

      <div className="mt-4">
        {activeTab === 'overview' && (
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium mb-1">Project Title</label>
              <input
                type="text"
                className="w-full p-2 border rounded"
                value={formData.title}
                onChange={(e) => setFormData({ ...formData, title: e.target.value })}
              />
            </div>
            <div>
              <label className="block text-sm font-medium mb-1">Description</label>
              <textarea
                className="w-full p-2 border rounded h-32 resize-none"
                value={formData.description}
                onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                placeholder="Describe your project in detail..."
              />
            </div>
          </div>
        )}

        {activeTab === 'technical' && (
          <div className="space-y-4">
            {Object.entries(formData.technicalStack || {}).map(([category, technologies]) => (
              <div key={category} className="bg-white p-4 rounded border">
                <h4 className="font-medium capitalize mb-2">{category}</h4>
                <div className="space-y-2">
                  {technologies.map((tech, index) => (
                    <div key={index} className="flex items-center gap-2">
                      <input
                        type="text"
                        className="flex-1 p-2 border rounded"
                        value={tech}
                        onChange={(e) => {
                          const newTech = [...technologies];
                          newTech[index] = e.target.value;
                          setFormData({
                            ...formData,
                            technicalStack: {
                              ...formData.technicalStack,
                              [category]: newTech
                            }
                          });
                        }}
                      />
                      <button
                        onClick={() => {
                          const newTech = technologies.filter((_, i) => i !== index);
                          setFormData({
                            ...formData,
                            technicalStack: {
                              ...formData.technicalStack,
                              [category]: newTech
                            }
                          });
                        }}
                        className="text-red-500 hover:text-red-700"
                      >
                        <FaTrash size={16} />
                      </button>
                    </div>
                  ))}
                  <button
                    onClick={() => {
                      setFormData({
                        ...formData,
                        technicalStack: {
                          ...formData.technicalStack,
                          [category]: [...technologies, '']
                        }
                      });
                    }}
                    className="text-sm text-blue-500 hover:text-blue-700"
                  >
                    + Add Technology
                  </button>
                </div>
              </div>
            ))}
          </div>
        )}

        {activeTab === 'features' && (
          <div className="space-y-4">
            {formData.coreFeatures.map((feature, index) => (
              <div key={index} className="bg-white p-4 rounded border">
                <div className="flex justify-between items-start mb-4">
                  <input
                    type="text"
                    className="text-lg font-medium p-1 border rounded"
                    value={feature.name}
                    onChange={(e) => {
                      const newFeatures = [...formData.coreFeatures];
                      newFeatures[index] = { ...feature, name: e.target.value };
                      setFormData({ ...formData, coreFeatures: newFeatures });
                    }}
                  />
                  <button
                    onClick={() => {
                      const newFeatures = formData.coreFeatures.filter((_, i) => i !== index);
                      setFormData({ ...formData, coreFeatures: newFeatures });
                    }}
                    className="text-red-500 hover:text-red-700"
                  >
                    <FaTrash size={16} />
                  </button>
                </div>
                <textarea
                  className="w-full p-2 border rounded mb-4"
                  value={feature.description}
                  onChange={(e) => {
                    const newFeatures = [...formData.coreFeatures];
                    newFeatures[index] = { ...feature, description: e.target.value };
                    setFormData({ ...formData, coreFeatures: newFeatures });
                  }}
                  placeholder="Feature description..."
                />
              </div>
            ))}
          </div>
        )}

        {activeTab === 'milestones' && (
          <div className="space-y-4">
            {formData.milestones.map((milestone, index) => (
              <div key={index} className="bg-white p-4 rounded border">
                <div className="flex justify-between items-start mb-4">
                  <div className="flex-1 space-y-2">
                    <input
                      type="text"
                      className="w-full p-2 border rounded"
                      value={milestone.title}
                      onChange={(e) => {
                        const newMilestones = [...formData.milestones];
                        newMilestones[index] = { ...milestone, title: e.target.value };
                        setFormData({ ...formData, milestones: newMilestones });
                      }}
                    />
                    <input
                      type="text"
                      className="w-full p-2 border rounded"
                      value={milestone.category}
                      onChange={(e) => {
                        const newMilestones = [...formData.milestones];
                        newMilestones[index] = { ...milestone, category: e.target.value };
                        setFormData({ ...formData, milestones: newMilestones });
                      }}
                    />
                    <input
                      type="text"
                      className="w-full p-2 border rounded"
                      value={milestone.timeline}
                      onChange={(e) => {
                        const newMilestones = [...formData.milestones];
                        newMilestones[index] = { ...milestone, timeline: e.target.value };
                        setFormData({ ...formData, milestones: newMilestones });
                      }}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}

        {activeTab === 'budget' && (
          <div className="space-y-4">
            <div className="bg-white p-4 rounded border">
              <label className="block text-sm font-medium mb-1">Total Estimate</label>
              <input
                type="text"
                className="w-full p-2 border rounded mb-4"
                value={formData.budget?.estimate}
                onChange={(e) => setFormData({
                  ...formData,
                  budget: { ...formData.budget, estimate: e.target.value }
                })}
              />

              <h4 className="font-medium mb-2">Budget Breakdown</h4>
              {Object.entries(formData.budget?.breakdown || {}).map(([category, cost]) => (
                <div key={category} className="flex items-center gap-2 mb-2">
                  <input
                    type="text"
                    className="flex-1 p-2 border rounded"
                    value={category}
                    placeholder="Category"
                    onChange={(e) => {
                      const newBreakdown = { ...formData.budget.breakdown };
                      delete newBreakdown[category];
                      newBreakdown[e.target.value] = cost;
                      setFormData({
                        ...formData,
                        budget: { ...formData.budget, breakdown: newBreakdown }
                      });
                    }}
                  />
                  <input
                    type="text"
                    className="w-32 p-2 border rounded"
                    value={cost}
                    onChange={(e) => {
                      const newBreakdown = { ...formData.budget.breakdown };
                      newBreakdown[category] = e.target.value;
                      setFormData({
                        ...formData,
                        budget: { ...formData.budget, breakdown: newBreakdown }
                      });
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
        )}

        {activeTab === 'preview' && (
          <DevPreview formData={formData} />
        )}
      </div>
    </div>
  );
};

export { ProjectReview, INITIAL_FORM_STATE };