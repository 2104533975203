import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { 
  Star, Bookmark, Filter, MessageSquare, PlusCircle, 
  ChevronLeft, ChevronRight, Calendar, DollarSign,
  Clock, Users, TrendingUp, AlertCircle, Award,
  ArrowLeft
} from 'lucide-react';

const ProjectsList = () => {
  const { categoryId } = useParams();
  const navigate = useNavigate();
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [categoryInfo, setCategoryInfo] = useState(null);
  const [filters, setFilters] = useState({ 
    search: '', 
    skills: [], 
    budget: [0, 10000],
    projectType: 'all',
    duration: 'all',
    complexity: 'all'
  });
  const [sortBy, setSortBy] = useState('recent');
  const [favorites, setFavorites] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 9;

  useEffect(() => {
    const fetchCategoryAndProjects = async () => {
      setLoading(true);
      try {
        // Mock category info
        setCategoryInfo({
          id: categoryId,
          title: 'Web Development',
          description: 'Find web development projects ranging from simple websites to complex web applications.',
          totalProjects: 234,
          popularSkills: ['React', 'Node.js', 'Python', 'JavaScript', 'TypeScript']
        });

        // Mock projects data
        const data = Array(12).fill(null).map((_, index) => ({
          id: index + 1,
          title: `Sample Web Development Project ${index + 1}`,
          description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
          skills: ['React', 'Node.js', 'TypeScript'],
          budget: Math.floor(Math.random() * 8000) + 2000,
          deadline: '2024-03-31',
          ownerRating: 4.5,
          applications: Math.floor(Math.random() * 20),
          favorite: false,
          complexity: 'Intermediate',
          duration: '2 months',
          projectType: 'Fixed-price',
          urgency: index % 3 === 0 ? 'High' : 'Normal',
          owner: {
            name: 'John Doe',
            rating: 4.8,
            completedProjects: 15,
            avatar: `https://via.placeholder.com/32`
          },
          lastActivity: '2 hours ago'
        }));

        setProjects(data);
      } catch (error) {
        console.error('Error fetching category data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchCategoryAndProjects();
  }, [categoryId]);

  const ProjectCard = ({ project }) => (
    <Link to={`/projects/${project.id}`} className="block">
      <div className="bg-white rounded-lg shadow-sm hover:shadow-md transition-all duration-200 border border-gray-200 group h-full">
        <div className="p-4 flex flex-col h-full">
          <div className="flex justify-between items-start">
            <div>
              <h3 className="text-lg font-semibold group-hover:text-blue-600">
                {project.title}
              </h3>
              <div className="flex items-center text-sm text-gray-500 mt-1">
                <Clock className="w-4 h-4 mr-1" />
                <span>Posted {project.lastActivity}</span>
              </div>
            </div>
            <div className="flex gap-2">
              {project.urgency === 'High' && (
                <div className="bg-red-50 text-red-800 py-1 px-2 text-xs rounded flex items-center">
                  <AlertCircle className="w-4 h-4 mr-1" />
                  <span>Urgent</span>
                </div>
              )}
              <button
                onClick={(e) => {
                  e.preventDefault();
                  toggleFavorite(project.id);
                }}
                className={`p-1 rounded ${
                  favorites.includes(project.id) ? 'text-yellow-500' : 'text-gray-400'
                }`}
              >
                <Bookmark className="w-5 h-5" />
              </button>
            </div>
          </div>

          <p className="text-gray-600 mt-2 line-clamp-2">{project.description}</p>
          
          <div className="grid grid-cols-2 gap-4 my-4">
            <div className="flex items-center">
              <DollarSign className="w-4 h-4 text-gray-500 mr-2" />
              <span className="font-semibold">${project.budget}</span>
            </div>
            <div className="flex items-center">
              <Clock className="w-4 h-4 text-gray-500 mr-2" />
              <span>{project.duration}</span>
            </div>
          </div>

          <div className="flex flex-wrap gap-2 mb-4">
            {project.skills.map((skill) => (
              <span
                key={skill}
                className="px-2 py-1 bg-blue-50 text-blue-600 text-xs rounded"
              >
                {skill}
              </span>
            ))}
          </div>

          <div className="flex items-center justify-between pt-4 border-t mt-auto">
            <div className="flex items-center">
              <img
                src={project.owner.avatar}
                alt={project.owner.name}
                className="w-6 h-6 rounded-full mr-2"
              />
              <div className="flex items-center text-sm text-gray-500">
                <Star className="w-4 h-4 text-yellow-400 mr-1" />
                {project.owner.rating}
              </div>
            </div>
            <span className="text-sm text-gray-500">
              {project.applications} applicants
            </span>
          </div>
        </div>
      </div>
    </Link>
  );

  const toggleFavorite = (id) => {
    setFavorites((prev) =>
      prev.includes(id) ? prev.filter((favId) => favId !== id) : [...prev, id]
    );
  };

  return (
    <div className="p-6">
      <div className="flex items-center justify-between mb-6">
        <button
          onClick={() => navigate(-1)}
          className="flex items-center text-blue-500 hover:text-blue-600"
        >
          <ArrowLeft className="w-5 h-5 mr-2" />
          Back
        </button>
        <h1 className="text-2xl font-semibold">{categoryInfo?.title || 'Projects'}</h1>
      </div>
      <p className="text-gray-500 mb-6">{categoryInfo?.description}</p>

      {loading ? (
        <p>Loading projects...</p>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {projects.map((project) => (
            <ProjectCard key={project.id} project={project} />
          ))}
        </div>
      )}
    </div>
  );
};

export default ProjectsList;
