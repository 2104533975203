// src/components/Messenger/RightSidebar.js
import React from 'react';

const RightSidebar = () => {
  return (
    <div className="w-80 bg-white border-l">
      <div className="p-4 border-b">
        <h2 className="font-semibold">Chat Info</h2>
      </div>
      <div className="p-4">
        <h3 className="text-sm font-medium text-gray-500 mb-2">Shared Files</h3>
        <div className="space-y-2">
          <div className="flex items-center p-2 bg-gray-50 rounded">
            <div className="w-8 h-8 bg-blue-100 rounded flex items-center justify-center text-blue-500">
              📄
            </div>
            <div className="ml-3">
              <div className="text-sm font-medium">Document.pdf</div>
              <div className="text-xs text-gray-500">2.3 MB</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RightSidebar;