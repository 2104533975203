import React, { useState, useEffect } from 'react';
import { FaPlus, FaTrash, FaEdit, FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import api from '../../services/api';
import { ProjectReview, INITIAL_FORM_STATE } from './ProjectReview';
import { generateProjectSuggestions } from '../../services/aiService';

const OwnerProjectsPage = () => {
  const [projects, setProjects] = useState([]);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [step, setStep] = useState('describe');
  const [formError, setFormError] = useState(null);
  const [formData, setFormData] = useState(INITIAL_FORM_STATE);
  const [editingProjectId, setEditingProjectId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [filter, setFilter] = useState('all');
  const LIMIT = 5;

  useEffect(() => {
    fetchProjects();
  }, [currentPage, filter]);

  const fetchProjects = async () => {
    try {
      const response = await api.get(
        `/projects?page=${currentPage}&limit=${LIMIT}&filter=${filter}`
      );
      setProjects(response.data.projects);
      setTotalPages(response.data.totalPages);
    } catch (err) {
      setFormError('Failed to load projects');
      console.error('Error fetching projects:', err);
    }
  };

  const resetForm = () => {
    setFormData(INITIAL_FORM_STATE);
    setStep('describe');
    setFormError(null);
    setIsFormOpen(false);
    setEditingProjectId(null);
  };

  const handleSubmit = async () => {
    setIsProcessing(true);
    setFormError(null);

    const token = localStorage.getItem('token');
    if (!token) {
      setFormError('Authentication token is missing. Please log in.');
      setIsProcessing(false);
      return;
    }

    try {
      if (editingProjectId) {
        const response = await api.put(`/projects/${editingProjectId}`, formData, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setProjects((prev) =>
          prev.map((project) =>
            project._id === editingProjectId ? { ...project, ...response.data } : project
          )
        );
      } else {
        const response = await api.post('/projects/createProject', formData, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setProjects([...projects, response.data.project]);
      }
      resetForm();
    } catch (err) {
      handleSubmitError(err);
    } finally {
      setIsProcessing(false);
    }
  };

  const handleSubmitError = (err) => {
    if (err.response?.status === 401) {
      setFormError('Access denied. Please log in again.');
    } else if (err.response?.data?.error) {
      setFormError(err.response.data.error);
    } else {
      setFormError('Failed to create or update project. Please try again.');
    }
    console.error('Submit error:', err);
  };

  const handleAnalyzeRequirements = async () => {
    if (!formData.description.trim()) {
      setFormError('Please enter a project description');
      return;
    }

    setIsProcessing(true);
    setFormError(null);

    try {
      const suggestions = await generateProjectSuggestions(formData.description);
      setFormData((prev) => ({
        ...prev,
        ...suggestions,
        budget: suggestions.budget || { estimate: '', breakdown: {} },
      }));
      setStep('review');
    } catch (err) {
      setFormError('Failed to analyze project. Please try again.');
      console.error('Analysis error:', err);
    } finally {
      setIsProcessing(false);
    }
  };

  const handleEditProject = (project) => {
    setFormData({
      ...project,
      budget: project.budget || { estimate: '', breakdown: {} },
    });
    setEditingProjectId(project._id);
    setIsFormOpen(true);
    setStep('review');
  };

  const handleDeleteProject = async (projectId) => {
    if (!window.confirm('Are you sure you want to delete this project?')) return;
    
    try {
      const token = localStorage.getItem('token');
      await api.delete(`/projects/${projectId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setProjects((prev) => prev.filter((project) => project._id !== projectId));
    } catch (err) {
      console.error('Failed to delete project:', err);
      setFormError('Failed to delete project. Please try again.');
    }
  };

  const filteredProjects = projects.filter((project) =>
    project.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
    project.description.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="max-w-6xl mx-auto p-6">
      {/* Header Section */}
      <div className="flex justify-between items-center mb-8">
        <h1 className="text-3xl font-bold text-gray-800">My Projects</h1>
        <div className="flex items-center gap-4">
          {/* Search Bar */}
          <div className="relative">
            <input
              type="text"
              placeholder="Search projects..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-64 px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          
          {/* Filter Dropdown */}
          <select
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
            className="px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            <option value="all">All Projects</option>
            <option value="active">Active</option>
            <option value="completed">Completed</option>
            <option value="draft">Drafts</option>
          </select>

          {/* Create Button */}
          <button
            onClick={() => setIsFormOpen(true)}
            className="flex items-center gap-2 bg-blue-600 text-white px-6 py-2 rounded-lg hover:bg-blue-700 transition-colors"
          >
            <FaPlus /> Create Project
          </button>
        </div>
      </div>

      {/* Error Messages */}
      {formError && (
        <div className="mb-6 p-4 bg-red-50 border border-red-200 rounded-lg text-red-700">
          {formError}
        </div>
      )}

      {/* Project List */}
      <div className="grid grid-cols-1 gap-6">
        {filteredProjects.map((project) => (
          <div
            key={project._id}
            className="bg-white rounded-lg shadow-sm border border-gray-200 hover:border-blue-300 transition-colors"
          >
            <div className="p-6">
              <div className="flex justify-between items-start mb-4">
                <div>
                  <h3 className="text-xl font-bold text-gray-800 mb-2">{project.title}</h3>
                  <p className="text-gray-600">{project.description}</p>
                </div>
                <div className="flex gap-3">
                  <button
                    onClick={() => handleEditProject(project)}
                    className="text-blue-600 hover:bg-blue-50 p-2 rounded-lg transition-colors"
                  >
                    <FaEdit size={18} />
                  </button>
                  <button
                    onClick={() => handleDeleteProject(project._id)}
                    className="text-red-600 hover:bg-red-50 p-2 rounded-lg transition-colors"
                  >
                    <FaTrash size={18} />
                  </button>
                </div>
              </div>

              {/* Project Details */}
              <div className="grid grid-cols-3 gap-4 mb-4">
                <div className="p-3 bg-gray-50 rounded-lg">
                  <p className="text-sm text-gray-500">Budget</p>
                  <p className="font-medium">{project.budget?.estimate || 'Not set'}</p>
                </div>
                <div className="p-3 bg-gray-50 rounded-lg">
                  <p className="text-sm text-gray-500">Timeline</p>
                  <p className="font-medium">
                    {project.milestones?.length || 0} milestones
                  </p>
                </div>
                <div className="p-3 bg-gray-50 rounded-lg">
                  <p className="text-sm text-gray-500">Status</p>
                  <p className="font-medium capitalize">{project.status || 'Draft'}</p>
                </div>
              </div>

              {/* Milestone Preview */}
              {project.milestones?.length > 0 && (
                <div>
                  <h4 className="font-medium mb-2">Milestones</h4>
                  <div className="space-y-2">
                    {project.milestones.map((milestone, index) => (
                      <div
                        key={index}
                        className="flex justify-between items-center p-3 bg-gray-50 rounded-lg"
                      >
                        <span>{milestone.title}</span>
                        <span className="text-sm text-gray-500">{milestone.timeline}</span>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>

      {/* Pagination */}
      <div className="flex justify-between items-center mt-8">
        <button
          onClick={() => setCurrentPage(Math.max(1, currentPage - 1))}
          disabled={currentPage === 1}
          className="flex items-center gap-2 px-4 py-2 border rounded-lg hover:bg-gray-50 disabled:opacity-50"
        >
          <FaArrowLeft /> Previous
        </button>
        <span className="text-gray-600">
          Page {currentPage} of {totalPages}
        </span>
        <button
          onClick={() => setCurrentPage(Math.min(totalPages, currentPage + 1))}
          disabled={currentPage === totalPages}
          className="flex items-center gap-2 px-4 py-2 border rounded-lg hover:bg-gray-50 disabled:opacity-50"
        >
          Next <FaArrowRight />
        </button>
      </div>

      {/* Project Creation/Edit Modal */}
      {isFormOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
          <div className="bg-white rounded-lg p-6 w-full max-w-4xl max-h-[90vh] overflow-y-auto">
            <div className="flex justify-between items-center mb-6">
              <h2 className="text-2xl font-semibold">
                {editingProjectId ? 'Edit Project' : 'Create New Project'}
              </h2>
              <button
                onClick={resetForm}
                className="text-gray-500 hover:text-gray-700"
              >
                <FaPlus className="rotate-45" size={24} />
              </button>
            </div>

            <ProjectReview formData={formData} setFormData={setFormData} />

            {step === 'describe' && (
              <button
                onClick={handleAnalyzeRequirements}
                disabled={isProcessing}
                className="w-full bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 disabled:opacity-50 mt-4"
              >
                {isProcessing ? 'Analyzing...' : 'Analyze Requirements'}
              </button>
            )}

            <div className="flex justify-end gap-4 mt-6">
              <button
                onClick={resetForm}
                className="px-6 py-2 border rounded-lg hover:bg-gray-50"
              >
                Cancel
              </button>
              <button
                onClick={handleSubmit}
                disabled={isProcessing}
                className="px-6 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 disabled:opacity-50"
              >
                {isProcessing ? 'Processing...' : editingProjectId ? 'Save Changes' : 'Create Project'}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default OwnerProjectsPage;