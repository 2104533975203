import React, { useState } from 'react';
import { CheckCircle, AlertCircle, Send, XCircle, Loader } from 'lucide-react';
import socket from '../../services/socket';
import { sendMessage } from '../../services/messengerService';
import api from '../../services/api';



const SubmitProposalModal = ({ onClose, onSubmit, project }) => {
  // State for form inputs
  const [budget, setBudget] = useState(project?.budget || '');
  const [coverLetter, setCoverLetter] = useState('');
  const [portfolioLinks, setPortfolioLinks] = useState('');
  const [availability, setAvailability] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);



// SubmitProposalModal.jsx
const handleSubmit = async () => {
  if (!budget || !coverLetter || !availability) {
    setError('Please fill in all required fields.');
    return;
  }

  try {
    console.log('Submitting proposal with data:', {
      projectId: project._id,
      ownerId: project.ownerId,
      content: coverLetter,
      proposal: {
        budget,
        coverLetter,
        availability,
      }
    });

    // First create a new chat with initial proposal
    const response = await api.post('/messenger/chat', {
      projectId: project._id,
      ownerId: project.ownerId,
      content: coverLetter,
      proposal: {
        budget,
        availability,
        coverLetter
      }
    });

    console.log('Proposal submitted successfully:', response.data);
    onClose();
  } catch (error) {
    console.error('Error submitting proposal:', error);
    setError('Failed to submit proposal. Please try again.');
  }
};
  console.log('🧠 Owner ID Passed to Modal:', project?.ownerId);

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white max-w-2xl w-full rounded-lg shadow-lg p-6">
        
        {/* Modal Header */}
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold">Submit Your Proposal</h2>
          <button 
            onClick={onClose} 
            className="text-gray-500 hover:text-gray-700"
          >
            <XCircle size={24} />
          </button>
        </div>

        {/* Modal Body */}
        <div className="space-y-4">
          
          {/* Budget Input */}
          <div>
            <label className="block text-sm font-medium mb-1">Proposed Budget ($) *</label>
            <input
              type="number"
              value={budget}
              onChange={(e) => setBudget(e.target.value)}
              className="w-full px-3 py-2 border rounded-md focus:ring focus:ring-blue-200"
              placeholder="Enter your budget"
            />
          </div>

          {/* Cover Letter Input */}
          <div>
            <label className="block text-sm font-medium mb-1">Cover Letter *</label>
            <textarea
              value={coverLetter}
              onChange={(e) => setCoverLetter(e.target.value)}
              rows="4"
              className="w-full px-3 py-2 border rounded-md focus:ring focus:ring-blue-200"
              placeholder="Explain why you're the best fit for this project"
            />
          </div>

          {/* Portfolio Links */}
          <div>
            <label className="block text-sm font-medium mb-1">Portfolio Links</label>
            <input
              type="text"
              value={portfolioLinks}
              onChange={(e) => setPortfolioLinks(e.target.value)}
              className="w-full px-3 py-2 border rounded-md focus:ring focus:ring-blue-200"
              placeholder="Add links to your portfolio"
            />
          </div>

          {/* Availability */}
          <div>
            <label className="block text-sm font-medium mb-1">Availability *</label>
            <input
              type="text"
              value={availability}
              onChange={(e) => setAvailability(e.target.value)}
              className="w-full px-3 py-2 border rounded-md focus:ring focus:ring-blue-200"
              placeholder="e.g., 10 hours/week"
            />
          </div>

          {/* Error Message */}
          {error && (
            <div className="flex items-center text-red-500 mt-2">
              <AlertCircle size={18} className="mr-2" />
              {error}
            </div>
          )}
        </div>

        {/* Modal Footer */}
        <div className="flex justify-end gap-4 mt-6">
          <button
            onClick={onClose}
            className="px-4 py-2 text-gray-600 border rounded-md hover:bg-gray-50 transition-colors"
            disabled={loading}
          >
            Cancel
          </button>
          <button
            onClick={handleSubmit}
            className={`px-4 py-2 rounded-md transition-colors flex items-center ${
              loading ? 'bg-gray-400' : 'bg-blue-500 hover:bg-blue-600 text-white'
            }`}
            disabled={loading}
          >
            {loading ? (
              <>
                <Loader size={16} className="animate-spin mr-2" />
                Submitting...
              </>
            ) : (
              <>
                <Send size={16} className="mr-2" />
                Submit Proposal
              </>
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default SubmitProposalModal;
