import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Upload, File, Send, AlertCircle } from 'lucide-react';
import api from '../../services/api';

// Order Details Page
const OrderDetails = () => {
  const { orderId } = useParams();
  const [order, setOrder] = useState(null);
  const [files, setFiles] = useState([]);
  const [feedback, setFeedback] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchOrder = async () => {
      try {
        const response = await api.get(`/orders/${orderId}`);
        setOrder(response.data);
      } catch (error) {
        setError('Failed to load order details');
      }
    };
    fetchOrder();
  }, [orderId]);

  const handleFileUpload = async (e) => {
    const uploadedFiles = Array.from(e.target.files);
    setFiles([...files, ...uploadedFiles]);
  };

  const handleSubmitDeliverable = async () => {
    try {
      const formData = new FormData();
      files.forEach(file => formData.append('files', file));
      formData.append('feedback', feedback);

      await api.post(`/orders/${orderId}/deliverable`, formData);
    } catch (error) {
      setError('Failed to submit deliverable');
    }
  };

  const handleReview = async (status) => {
    try {
      await api.post(`/orders/${orderId}/review`, {
        status,
        feedback
      });
    } catch (error) {
      setError('Failed to submit review');
    }
  };

  return (
    <div className="max-w-4xl mx-auto p-6">
      <div className="bg-white rounded-lg shadow-sm p-6">
        {/* Order Status */}
        <div className="mb-6">
          <h2 className="text-2xl font-bold mb-4">Order Details</h2>
          <div className="flex items-center gap-2">
            <div className={`px-3 py-1 rounded-full text-sm ${
              order?.status === 'completed' ? 'bg-green-100 text-green-700' : 
              order?.status === 'disputed' ? 'bg-red-100 text-red-700' :
              'bg-blue-100 text-blue-700'
            }`}>
              {order?.status}
            </div>
          </div>
        </div>

        {/* Deliverables Section */}
        {order?.status !== 'completed' && (
          <div className="mb-6">
            <h3 className="text-lg font-semibold mb-4">Submit Deliverables</h3>
            <div className="space-y-4">
              <div className="border-2 border-dashed rounded-lg p-4 text-center">
                <input
                  type="file"
                  multiple
                  onChange={handleFileUpload}
                  className="hidden"
                  id="file-upload"
                />
                <label htmlFor="file-upload" className="cursor-pointer">
                  <Upload className="mx-auto h-12 w-12 text-gray-400" />
                  <span className="mt-2 block text-sm text-gray-600">
                    Click to upload files
                  </span>
                </label>
              </div>

              {files.length > 0 && (
                <div className="space-y-2">
                  {files.map((file, index) => (
                    <div key={index} className="flex items-center gap-2">
                      <File className="h-4 w-4" />
                      <span>{file.name}</span>
                    </div>
                  ))}
                </div>
              )}

              <textarea
                placeholder="Add comments or description..."
                value={feedback}
                onChange={(e) => setFeedback(e.target.value)}
                className="w-full p-2 border rounded-md"
                rows={4}
              />

              <button
                onClick={handleSubmitDeliverable}
                className="w-full py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
              >
                Submit Deliverable
              </button>
            </div>
          </div>
        )}

        {/* Review Section */}
        {order?.status === 'review' && (
          <div className="mb-6">
            <h3 className="text-lg font-semibold mb-4">Review Submission</h3>
            <div className="space-y-4">
              <textarea
                placeholder="Provide feedback..."
                value={feedback}
                onChange={(e) => setFeedback(e.target.value)}
                className="w-full p-2 border rounded-md"
                rows={4}
              />

              <div className="flex gap-4">
                <button
                  onClick={() => handleReview('approved')}
                  className="flex-1 py-2 bg-green-500 text-white rounded-md hover:bg-green-600"
                >
                  Approve
                </button>
                <button
                  onClick={() => handleReview('revision')}
                  className="flex-1 py-2 bg-yellow-500 text-white rounded-md hover:bg-yellow-600"
                >
                  Request Revision
                </button>
              </div>
            </div>
          </div>
        )}

        {/* Dispute Section */}
        {order?.status !== 'disputed' && (
          <div>
            <button
              onClick={() => setOrder({ ...order, status: 'disputed' })}
              className="text-red-500 hover:text-red-600"
            >
              Open Dispute
            </button>
          </div>
        )}

        {error && (
          <div className="mt-4 p-4 bg-red-100 text-red-700 rounded-md flex items-center gap-2">
            <AlertCircle className="h-5 w-5" />
            {error}
          </div>
        )}
      </div>
    </div>
  );
};

export default OrderDetails;