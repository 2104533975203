import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Eye, EyeOff, Mail, Lock, AlertCircle } from 'lucide-react';
import api from '../../services/api';
import { useAuth } from '../../context/AuthContext';

const Signup = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const navigate = useNavigate();
  const { login } = useAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError('');
    setSuccess('');

    if (!email || !password) {
      setError('Email and password are required.');
      setIsSubmitting(false);
      return;
    }

    try {
      // Send request to backend
      const response = await api.post('/auth/signup', {
        email,
        password,
      });

      const { userId, token, isAdmin } = response.data;

      if (!userId || !token) {
        throw new Error('Missing userId or token in the response.');
      }

      // Store userId, token, and isAdmin in localStorage
      localStorage.setItem('userId', userId);
      localStorage.setItem('token', token);
      localStorage.setItem('isAdmin', (isAdmin || false).toString());

      // Auth Context Login
      login(email, userId, token, isAdmin || false);

      setSuccess('Signup successful! Redirecting to Profile Setup...');
      setTimeout(() => {
        navigate('/onboarding/profilesetup');
      }, 1500);
    } catch (err) {
      console.error('Signup error:', err.response?.data || err.message);
      setError(
        err.response?.data?.error || 'An unexpected error occurred. Please try again.'
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="w-full max-w-md px-6">
        <form onSubmit={handleSubmit} className="bg-white p-8 rounded-lg shadow-lg">
          {/* Header */}
          <h2 className="text-2xl font-bold text-center mb-4">Create an Account</h2>
          {error && (
            <div className="mb-4 text-red-500 flex items-center">
              <AlertCircle className="mr-2" /> {error}
            </div>
          )}
          {success && (
            <div className="mb-4 text-green-500">{success}</div>
          )}

          {/* Email Input */}
          <div className="mb-4">
            <label className="block text-gray-700">Email</label>
            <div className="relative">
              <Mail className="absolute inset-y-0 left-3 text-gray-400" />
              <input
                type="email"
                className="pl-10 pr-3 py-2 border rounded-md w-full"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
          </div>

          {/* Password Input */}
          <div className="mb-4">
            <label className="block text-gray-700">Password</label>
            <div className="relative">
              <Lock className="absolute inset-y-0 left-3 text-gray-400" />
              <input
                type={showPassword ? 'text' : 'password'}
                className="pl-10 pr-10 py-2 border rounded-md w-full"
                placeholder="Enter your password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <button
                type="button"
                className="absolute inset-y-0 right-3"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? <EyeOff /> : <Eye />}
              </button>
            </div>
          </div>

          {/* Submit Button */}
          <button
            type="submit"
            disabled={isSubmitting}
            className="w-full bg-blue-600 text-white py-2 rounded-md hover:bg-blue-700"
          >
            {isSubmitting ? 'Creating Account...' : 'Sign Up'}
          </button>
          <p className="text-sm mt-4 text-center">
            Already have an account?{' '}
            <Link to="/login" className="text-blue-600">
              Log In
            </Link>
          </p>
        </form>
      </div>
    </div>
  );
};

export default Signup;
