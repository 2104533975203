// src/components/Messenger/ProposalSection.js
import React from 'react';
import { CheckCircle, XCircle } from 'lucide-react';

const ProposalSection = ({ selectedChat, onAcceptProposal, onDeclineProposal, user }) => {
  return (
    <div className="bg-blue-50 p-4 border-b">
      <div className="flex justify-between items-center">
        <div>
          <p className="font-medium">Proposal Details</p>
          <p className="text-sm">Budget: ${selectedChat.proposal.budget}</p>
          <p className="text-sm">Messages: {selectedChat.proposal.messageCount || 0}/10</p>
        </div>
        {selectedChat.ownerId === user.id && (
          <div className="flex gap-2">
            <button
              onClick={() => onAcceptProposal(selectedChat._id)}
              className="flex items-center px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600"
            >
              <CheckCircle className="w-5 h-5 mr-1" />
              Accept
            </button>
            <button
              onClick={() => onDeclineProposal(selectedChat._id)}
              className="flex items-center px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
            >
              <XCircle className="w-5 h-5 mr-1" />
              Decline
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProposalSection;