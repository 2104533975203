//admin dashboard
import React, { useEffect, useState } from 'react';
import api from '../../services/api';
import { useAuth } from '../../context/AuthContext';
import { Link, useNavigate } from 'react-router-dom';

const AdminDashboard = () => {
  const [users, setUsers] = useState([]);
  const [orders, setOrders] = useState([]);
  const [loadingUsers, setLoadingUsers] = useState(true);
  const [loadingOrders, setLoadingOrders] = useState(true);
  const [error, setError] = useState('');
  const [activeTab, setActiveTab] = useState('users'); // Tabs: 'users' or 'orders'
  const [searchTerm, setSearchTerm] = useState('');
  const [filterRole, setFilterRole] = useState('all'); // 'all', 'admin', 'user'
  const { user } = useAuth();
  const navigate = useNavigate();

  // Fetch Users
  const fetchUsers = async () => {
    try {
      setLoadingUsers(true);

      if (!user || !user.token) {
        throw new Error('No authentication token found.');
      }

      const response = await api.get('/admin/users', {
        headers: { Authorization: `Bearer ${user.token}` },
      });
      setUsers(response.data);
    } catch (err) {
      console.error('Error fetching users:', err.response?.data?.message || err.message);
      setError('Failed to load users.');
    } finally {
      setLoadingUsers(false);
    }
  };

  // Fetch Orders
  const fetchOrders = async () => {
    try {
      setLoadingOrders(true);

      const response = await api.get('/admin/orders', {
        headers: { Authorization: `Bearer ${user.token}` },
      });
      setOrders(response.data);
    } catch (err) {
      console.error('Error fetching orders:', err.response?.data?.message || err.message);
      setError('Failed to load orders.');
    } finally {
      setLoadingOrders(false);
    }
  };

  useEffect(() => {
    fetchUsers();
    fetchOrders();
  }, [user?.token]);

  // Filter Users
  const filteredUsers = users.filter((user) => {
    const matchesSearch = user.email.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesRole =
      filterRole === 'all' ||
      (filterRole === 'admin' && user.isAdmin) ||
      (filterRole === 'user' && !user.isAdmin);

    return matchesSearch && matchesRole;
  });

  // View as User Handler
  const handleViewAsUser = async (userId) => {
    try {
      const response = await api.get(`/admin/users/${userId}/view`, {
        headers: { Authorization: `Bearer ${user.token}` },
      });
  
      const { token, user: impersonatedUser } = response.data;
  
      // Store impersonation token in localStorage/sessionStorage
      localStorage.setItem('impersonationToken', token);
  
      // Redirect to dashboard or user profile page
      navigate('/dashboard');
    } catch (error) {
      console.error('❌ Failed to impersonate user:', error.response?.data?.error || error.message);
      setError('Failed to view as user. Please try again.');
    }
  };
  

  // Render User Table
  const renderUsers = () => (
    <div>
      {/* Search & Filter */}
      <div className="flex items-center gap-4 mb-4">
        <input
          type="text"
          placeholder="Search users by email..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="p-2 border rounded-md"
        />
        <select
          value={filterRole}
          onChange={(e) => setFilterRole(e.target.value)}
          className="p-2 border rounded-md"
        >
          <option value="all">All Roles</option>
          <option value="admin">Admin</option>
          <option value="user">User</option>
        </select>
      </div>

      {/* User Table */}
      {loadingUsers ? (
        <p className="text-gray-500">Loading Users...</p>
      ) : filteredUsers.length > 0 ? (
        <table className="w-full border-collapse border">
          <thead>
            <tr className="bg-gray-100">
              <th className="border p-2">Email</th>
              <th className="border p-2">Role</th>
              <th className="border p-2">Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredUsers.map((user) => (
              <tr key={user._id} className="hover:bg-gray-50">
                <td className="border p-2">{user.email}</td>
                <td className="border p-2">
                  <span className={user.isAdmin ? 'text-green-500' : 'text-gray-500'}>
                    {user.isAdmin ? 'Admin' : 'User'}
                  </span>
                </td>
                <td className="border p-2">
                  <button
                    onClick={() => handleViewAsUser(user._id)}
                    className="px-3 py-1 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition"
                  >
                    View as User
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p className="text-gray-500">No users found.</p>
      )}
    </div>
  );

  // Render Orders Table
  const renderOrders = () => (
    <div>
      {loadingOrders ? (
        <p className="text-gray-500">Loading Orders...</p>
      ) : orders.length > 0 ? (
        <table className="w-full border-collapse border">
          <thead>
            <tr className="bg-gray-100">
              <th className="border p-2">Order ID</th>
              <th className="border p-2">Status</th>
            </tr>
          </thead>
          <tbody>
            {orders.map((order) => (
              <tr key={order._id} className="hover:bg-gray-50">
                <td className="border p-2">{order._id}</td>
                <td className="border p-2 text-blue-500">{order.status}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p className="text-gray-500">No orders found.</p>
      )}
    </div>
  );

  return (
    <div className="p-6 max-w-6xl mx-auto bg-white shadow-md rounded-lg min-h-screen">
      <h1 className="text-4xl font-bold mb-6 text-gray-800">Admin Dashboard</h1>
      <div className="flex mb-6 border-b">
        <button onClick={() => setActiveTab('users')} className={`p-2 ${activeTab === 'users' ? 'border-b-2 border-blue-500' : ''}`}>Users</button>
        <button onClick={() => setActiveTab('orders')} className={`p-2 ${activeTab === 'orders' ? 'border-b-2 border-blue-500' : ''}`}>Orders</button>
      </div>
      {error && <p className="text-red-500 mb-4">{error}</p>}
      {activeTab === 'users' ? renderUsers() : renderOrders()}
    </div>
  );
};

export default AdminDashboard;
