import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import SubmitProposalModal from './SubmitProposalModal';
import {
  ArrowLeft,
  DollarSign,
  Calendar,
  Clock,
} from 'lucide-react';

const ProjectDetails = () => {
  const { projectId } = useParams();
  const navigate = useNavigate();

  const [project, setProject] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showProposalModal, setShowProposalModal] = useState(false);

  useEffect(() => {
    const fetchProject = async () => {
      setLoading(true);
      try {
        const response = await fetch(`http://localhost:5000/api/projects/${projectId}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`, 
          },
        });
  
        if (!response.ok) {
          throw new Error('Failed to fetch project details');
        }
  
        const data = await response.json();
        console.log('✅ Fetched Project Data:', data);
  
        // Ensure ownerId is directly set
        setProject({
          ...data.project,
          ownerId: data.project.owner?._id,
        });
      } catch (error) {
        console.error('❌ Error fetching project details:', error.message);
      } finally {
        setLoading(false);
      }
    };
  
    fetchProject();
  }, [projectId]);
  
  

  const handleProposalSubmit = (proposalData) => {
    console.log('Proposal Submitted:', proposalData);
    setShowProposalModal(false);
  };
  console.log('Project data:', project);
  console.log('🧠 Project Owner ID:', project?.ownerId);

  if (loading) {
    return (
      
      <div className="flex justify-center items-center h-96">
        <div className="text-lg text-gray-600">Loading project details...</div>
      </div>
    );
  }

  if (!project) {
    return (
      <div className="text-center p-6">Project not found.</div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto px-4 py-8">
      {/* Back Button */}
      <button
        onClick={() => navigate(-1)}
        className="flex items-center text-blue-500 hover:text-blue-600 mb-6"
      >
        <ArrowLeft className="w-5 h-5 mr-2" /> Back to Projects
      </button>

      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
        {/* Main Content */}
        <div className="lg:col-span-2 space-y-6">
          {/* Project Header */}
          <div className="bg-white rounded-lg shadow-sm border border-gray-200 p-6">
            <h1 className="text-2xl font-semibold mb-4">{project.title}</h1>
            <p className="text-gray-600 mb-6">{project.description}</p>
            
            <div className="grid grid-cols-2 md:grid-cols-3 gap-4 mb-6">
              {/* Budget */}
              <div className="flex items-center p-3 bg-gray-50 rounded-lg">
                <DollarSign className="w-5 h-5 text-gray-400 mr-2" />
                <div>
                  <p className="font-medium">${project.budget?.estimate || 'N/A'}</p>
                  <p className="text-xs text-gray-500">Budget</p>
                </div>
              </div>

              {/* Deadline */}
              <div className="flex items-center p-3 bg-gray-50 rounded-lg">
                <Calendar className="w-5 h-5 text-gray-400 mr-2" />
                <div>
                  <p className="font-medium">{project.deadline || 'N/A'}</p>
                  <p className="text-xs text-gray-500">Deadline</p>
                </div>
              </div>

              {/* Duration */}
              <div className="flex items-center p-3 bg-gray-50 rounded-lg">
                <Clock className="w-5 h-5 text-gray-400 mr-2" />
                <div>
                  <p className="font-medium">{project.duration || 'N/A'}</p>
                  <p className="text-xs text-gray-500">Duration</p>
                </div>
              </div>
            </div>

            {/* Requirements */}
            <h3 className="text-lg font-semibold mb-3">Requirements</h3>
            <ul className="list-disc pl-6 space-y-2 text-gray-600">
              {project.detailedRequirements?.map((req, index) => (
                <li key={index}>{req}</li>
              ))}
            </ul>

            {/* Skills */}
            <h3 className="text-lg font-semibold mt-6 mb-3">Required Skills</h3>
            <div className="flex flex-wrap gap-2">
              {project.skills?.map((skill) => (
                <span
                  key={skill}
                  className="px-3 py-1 bg-blue-50 text-blue-600 text-sm rounded-full"
                >
                  {skill}
                </span>
              ))}
            </div>
          </div>
        </div>

        {/* Sidebar */}
        <div className="space-y-6">
          {/* Owner Details */}
          <div className="bg-white rounded-lg shadow-sm border p-6">
            <div className="flex items-center mb-4">
              <img
                src={project.owner?.avatar || '/placeholder.jpg'}
                alt={project.owner?.name}
                className="w-12 h-12 rounded-full mr-4"
              />
              <div>
                <h4 className="font-semibold">{project.owner?.name}</h4>
                <p className="text-sm text-gray-500">Member since {project.owner?.memberSince}</p>
              </div>
            </div>
          </div>

          {/* Submit Proposal */}
          <button
            onClick={() => setShowProposalModal(true)}
            className="w-full px-4 py-3 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors font-medium"
          >
            Submit Proposal
          </button>
        </div>
      </div>

      {/* Proposal Modal */}
      {showProposalModal && (
       <SubmitProposalModal
       onClose={() => setShowProposalModal(false)}
       onSubmit={handleProposalSubmit}
       project={{ ...project, ownerId: project?.ownerId }}
     />
      )}
    </div>
  );
};

export default ProjectDetails;
