import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const Workspace = () => {
  const [workspaceName, setWorkspaceName] = useState('');
  const [option, setOption] = useState('create');
  const navigate = useNavigate();

  const handleNext = () => {
    navigate('/onboarding/success');
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="bg-white p-6 rounded shadow-md w-80">
        <h2 className="text-xl font-bold mb-4 text-gray-800">Workspace Setup</h2>
        <p className="text-gray-600 mb-4">
          Would you like to join an existing workspace or create a new one?
        </p>
        <div className="mb-4">
          <label className="block text-gray-600">
            <input
              type="radio"
              name="workspaceOption"
              value="create"
              checked={option === 'create'}
              onChange={() => setOption('create')}
              className="mr-2"
            />
            Create a Workspace
          </label>
          <label className="block text-gray-600">
            <input
              type="radio"
              name="workspaceOption"
              value="join"
              checked={option === 'join'}
              onChange={() => setOption('join')}
              className="mr-2"
            />
            Join an Existing Workspace
          </label>
        </div>
        {option === 'create' && (
          <div className="mb-4">
            <label className="block text-gray-600">Workspace Name</label>
            <input
              type="text"
              className="w-full px-3 py-2 border rounded"
              value={workspaceName}
              onChange={(e) => setWorkspaceName(e.target.value)}
            />
          </div>
        )}
        {option === 'join' && (
          <div className="mb-4">
            <label className="block text-gray-600">Invite Code</label>
            <input
              type="text"
              className="w-full px-3 py-2 border rounded"
            />
          </div>
        )}
        <button
          onClick={handleNext}
          className="w-full bg-blue-600 text-white py-2 rounded hover:bg-blue-700"
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default Workspace;
