import React, { useState } from "react";

const WhitepaperGuide = () => {
  const [menuVisible, setMenuVisible] = useState(false);

  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  };

  const sections = [
    { id: "about", title: "About DevTrust" },
    { id: "features", title: "Key Features" },
    { id: "ai_agents", title: "AI Agents & Treasury" },
    { id: "use_cases", title: "Use Cases" },
    { id: "governance", title: "Governance" },
    { id: "roadmap", title: "Roadmap" },
    { id: "security", title: "Security" },
    { id: "community", title: "Community and Partnerships" },
    { id: "call_to_action", title: "Call to Action" },
    { id: "conclusion", title: "Conclusion" },
  ];

  const content = {
    about: `
      DevTrust is a next-generation freelance platform designed to revolutionize project management and collaboration. By leveraging blockchain technology for secure, milestone-based payments and artificial intelligence (AI) for automation, DevTrust ensures a transparent, efficient, and equitable workflow for developers and project owners alike.

      At its core, DevTrust integrates cutting-edge tools, trust-based systems, and seamless communication channels to foster productivity and accountability. Whether managing complex technical projects or tracking milestones in real-time, DevTrust eliminates inefficiencies and empowers teams to focus on delivering results.

      DevTrust aspires to become the go-to platform for modern freelancing, offering security, automation, and trust for developers, project managers, and organizations worldwide.
    `,
    features: `
      DevTrust offers an extensive suite of features tailored to empower both project owners and developers:
      <ol>
        <li><strong>AI-Driven Project Creation:</strong> Simplify project setup with AI-powered tools that automatically generate project structures, milestones, and budgets based on user inputs and industry best practices.</li>
        <li><strong>Secure Escrow Payments:</strong> Blockchain-based escrow ensures funds are released only after milestone completion, fostering trust and accountability between developers and project owners.</li>
        <li><strong>Comprehensive Integration:</strong> Sync seamlessly with popular tools like GitHub, Jira, Slack, and others to enable real-time progress tracking and team collaboration.</li>
        <li><strong>Advanced Developer Tools:</strong> Access controlled bidding systems, milestone-specific notifications, and a reputation-based review system to highlight high-performing contributors.</li>
        <li><strong>Milestone Dependencies:</strong> Plan and manage dependencies across milestones to ensure projects stay on track with clearly defined goals and timelines.</li>
        <li><strong>Analytics & Insights:</strong> Gain actionable insights into project performance, milestone completion rates, and team efficiency through intuitive dashboards.</li>
        <li><strong>Community-Governed Treasury:</strong> (TBD) Establish a treasury system governed by the community to support ecosystem growth, fund innovative proposals, and reward active participants.</li>
        <li><strong>API for External Project Creation:</strong> Enable users to create, manage, and sync projects from external systems or tools using a robust API, ensuring seamless integration with DevTrust.</li>

      </ol>
    `,
    ai_agents: `
      DevTrust harnesses AI to automate time-consuming workflows and provide data-driven insights:
      <ul>
        <li><strong>Intelligent Bid Matching:</strong> AI analyzes project bids and matches developers to opportunities based on skills, experience, and performance metrics.</li>
        <li><strong>Automated Milestone Updates:</strong> Real-time updates keep stakeholders informed without manual effort.</li>
        <li><strong>Advanced Analytics:</strong> Detailed insights into team performance, project timelines, and financial allocations ensure projects run smoothly.</li>
        <li><strong>Community Treasury:</strong> The treasury system is governed by token holders to fund ecosystem development and innovative proposals.</li>
      </ul>
    `,
    use_cases: `
      DevTrust is designed for a variety of users, each benefiting from tailored solutions:
      <ul>
        <li><strong>Alice:</strong> A startup founder who uses DevTrust to manage her decentralized app (dApp) project. She sets up milestones with ease, tracks her team’s progress in real-time, and ensures secure payments upon task completion.</li>
        <li><strong>Bob:</strong> A freelance developer who submits controlled bids, completes tasks, and receives milestone payments through the secure escrow system.</li>
        <li><strong>Corporate Development Teams:</strong> Teams at mid-sized enterprises integrate their repositories with DevTrust to streamline milestone tracking, improve collaboration, and stay focused on delivering high-quality products.</li>
        <li><strong>Product Managers:</strong> Leverage DevTrust’s real-time analytics to make informed decisions, reduce overhead, and prioritize value delivery.</li>
      </ul>
    `,
    governance: `
      Decentralized governance is at the heart of DevTrust’s ecosystem. Our goal is to empower the community to shape the platform’s future:
      <ul>
        <li><strong>Proposal Submission:</strong> Community members can propose platform enhancements, feature developments, or treasury allocations.</li>
        <li><strong>Voting Mechanism:</strong> Token holders participate in decision-making by voting on proposals, ensuring the platform remains user-driven.</li>
        <li><strong>Rewards for Participation:</strong> Active participants in governance, including those who vote or create proposals, earn rewards for their contributions.</li>
        <li><strong>Transparency & Accountability:</strong> All decisions and treasury transactions are recorded on the blockchain, ensuring trust and accountability at every level.</li>
      </ul>
    `,
    roadmap: `
      DevTrust’s roadmap outlines its vision for growth and innovation:
      <ol>
        <li><strong>Phase 1:</strong> Develop the foundational AI-powered tools for project creation, milestone tracking, and analytics. Initial beta version launched. (In Progress)</li>
        <li><strong>Phase 2:</strong> Integrate blockchain-based escrow payments and establish decentralized governance mechanisms. (TBD)</li>
        <li><strong>Phase 3:</strong> Launch integrations with popular tools like GitHub, Jira, and Slack while introducing mobile apps for enhanced accessibility. (TBD)</li>
        <li><strong>Phase 4:</strong> Conduct extensive beta testing with key stakeholders and refine the platform based on user feedback. (TBD)</li>
        <li><strong>Phase 5:</strong> Public launch with targeted marketing campaigns and partnerships. (TBD)</li>
        <li><strong>Phase 6:</strong> Ecosystem expansion with advanced features like predictive AI analytics, multi-chain blockchain support, and enhanced developer reputation tracking. (TBD)</li>
      </ol>
    `,
    security: `
      Security is paramount for DevTrust:
      <ul>
        <li><strong>Data Encryption:</strong> All data is encrypted end-to-end to ensure privacy and protection.</li>
        <li><strong>Secure Escrow Contracts:</strong> Blockchain-powered escrow ensures transparency and security for payments.</li>
        <li><strong>Authentication:</strong> Multi-factor authentication (MFA) is required for all sensitive actions.</li>
      </ul>
    `,
    community: `
      DevTrust thrives on collaboration and partnerships:
      <ul>
        <li><strong>Developer Community:</strong> Engage with a global network of developers for collaboration and knowledge sharing.</li>
        <li><strong>Partnerships:</strong> DevTrust partners with industry leaders to enhance platform features and expand its ecosystem.</li>
        <li><strong>Support Initiatives:</strong> Training programs and resources are available for onboarding new users and fostering skill development.</li>
      </ul>
    `,
    call_to_action: `
      DevTrust is more than just a platform—it’s a movement to redefine how freelancers and project owners collaborate:
      <ul>
        <li>Join the Beta: Be among the first to experience the power of DevTrust.</li>
        <li>Invest in the Future: Help us build a secure, scalable, and revolutionary platform.</li>
        <li>Stay Connected: Sign up for updates and participate in governance to shape the evolution of DevTrust.</li>
      </ul>
    `,
    conclusion: `
      DevTrust combines trust, technology, and transparency to deliver a next-generation platform for freelancing. As we continue to develop and refine our tools, we invite you to join us on this journey to create a more secure, efficient, and equitable freelancing ecosystem.
    `,
  };

  return (
    <div className="flex min-h-screen bg-gray-100 font-roboto">
      {/* Sidebar */}
{/* Sidebar */}
<nav
  id="navbar"
  className={`bg-white border-r border-gray-200 w-64 fixed top-0 left-0 h-full p-6 transition-transform transform ${
    menuVisible ? "translate-x-0" : "-translate-x-full"
  } md:translate-x-0`}
  aria-label="Sidebar"
>
  <header className="text-center mb-6">
    <div>
      <img
        src="logo3.png"
        alt="DevTrust Logo"
        className="w-16 h-16 mx-auto"
      />
    </div>
    <p className="text-1xl text-gray-600 mt-3">DevTrust.io Whitepaper</p>
  </header>

  {/* Back to Home Button */}
  <button
    onClick={() => (window.location.href = "/")} // Change this if using react-router
    className="w-full bg-blue-500 text-white py-2 rounded text-center font-semibold mb-4 hover:bg-blue-600 transition"
  >
    Back to Home
  </button>

  {/* Close menu button */}
  <button
    className="md:hidden text-gray-600 text-2xl absolute top-4 right-4"
    onClick={toggleMenu}
    aria-label="Close Menu"
  >
    &#x2715;
  </button>

  <ul className="space-y-2">
    {sections.map((section) => (
      <li key={section.id}>
        <a
          className="nav-link block py-2 px-4 text-gray-700 hover:bg-gray-100 rounded transition duration-200"
          href={`#${section.id}`}
        >
          {section.title}
        </a>
      </li>
    ))}
  </ul>
</nav>


      {/* Main Content */}
      <main
        id="main-doc"
        className="flex-1 ml-0 md:ml-64 p-8 bg-white max-w-4xl mx-auto"
      >
        <button
          className="md:hidden bg-blue-500 text-white px-4 py-2 rounded mb-4"
          onClick={toggleMenu}
          aria-label="Open Menu"
        >
          Menu
        </button>
        {sections.map((section) => (
          <section
            key={section.id}
            id={section.id}
            className="main-section mb-12"
          >
            <header className="mb-6">
              <h1 className="text-3xl font-bold text-gray-800">{section.title}</h1>
              <hr className="border-t border-gray-200 my-4" />
            </header>
            <div
              className="text-gray-700 leading-relaxed text-justify"
              dangerouslySetInnerHTML={{ __html: content[section.id] }}
            ></div>
          </section>
        ))}
      </main>
    </div>
  );
};

export default WhitepaperGuide;
