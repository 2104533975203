import React, { useState } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

const Analytics = () => {
  const [isDarkMode, setIsDarkMode] = useState(false);

  const data = [
    { month: 'Jan', milestones: 10, earnings: 5000 },
    { month: 'Feb', milestones: 15, earnings: 7000 },
    { month: 'Mar', milestones: 12, earnings: 6000 },
    { month: 'Apr', milestones: 20, earnings: 9000 },
  ];

  return (
    <div className={`p-6 ${isDarkMode ? 'bg-gray-900 text-gray-100' : 'bg-white text-gray-900'} rounded-lg`}>
      <h1 className="text-2xl font-semibold mb-6">Analytics</h1>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {/* Milestone Analytics */}
        <div className={`p-4 rounded-lg ${isDarkMode ? 'bg-gray-800' : 'bg-gray-100'}`}>
          <h2 className="text-lg font-medium mb-4">Milestones Analytics</h2>
          <ResponsiveContainer width="100%" height={300}>
            <LineChart data={data}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="month" stroke={isDarkMode ? '#ffffff' : '#000000'} />
              <YAxis stroke={isDarkMode ? '#ffffff' : '#000000'} />
              <Tooltip contentStyle={isDarkMode ? { backgroundColor: '#374151' } : { backgroundColor: '#ffffff' }} />
              <Line type="monotone" dataKey="milestones" stroke="#3B82F6" strokeWidth={2} />
            </LineChart>
          </ResponsiveContainer>
        </div>

        {/* Earnings Analytics */}
        <div className={`p-4 rounded-lg ${isDarkMode ? 'bg-gray-800' : 'bg-gray-100'}`}>
          <h2 className="text-lg font-medium mb-4">Earnings Analytics</h2>
          <ResponsiveContainer width="100%" height={300}>
            <LineChart data={data}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="month" stroke={isDarkMode ? '#ffffff' : '#000000'} />
              <YAxis stroke={isDarkMode ? '#ffffff' : '#000000'} />
              <Tooltip contentStyle={isDarkMode ? { backgroundColor: '#374151' } : { backgroundColor: '#ffffff' }} />
              <Line type="monotone" dataKey="earnings" stroke="#22C55E" strokeWidth={2} />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
};

export default Analytics;
