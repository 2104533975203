import React, { useState } from 'react';
import { CheckCircle, Activity, X } from 'lucide-react';

const Milestones = () => {
  const [isDarkMode] = useState(false);
  const [milestones, setMilestones] = useState([
    { id: 1, name: 'Project Alpha', status: 'Completed', dueDate: '2024-01-15', payment: '$5,000' },
    { id: 2, name: 'Project Beta', status: 'In Progress', dueDate: '2024-02-20', payment: '$10,000' },
    { id: 3, name: 'Project Gamma', status: 'Pending', dueDate: '2024-03-10', payment: '$8,000' },
  ]);

  return (
    <div>
      <h1 className="text-2xl font-semibold mb-6">Milestones</h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
        {milestones.map((milestone) => (
          <div
            key={milestone.id}
            className={`p-4 rounded-lg border ${
              isDarkMode
                ? 'bg-gray-800 border-gray-700 hover:bg-gray-700'
                : 'bg-white border-gray-200 hover:shadow-md'
            }`}
          >
            <div className="flex items-center justify-between mb-2">
              <h2 className="text-lg font-medium">{milestone.name}</h2>
              {milestone.status === 'Completed' && (
                <div className="flex items-center space-x-1 text-green-500">
                  <CheckCircle className="w-4 h-4" />
                  <span className="text-sm">Completed</span>
                </div>
              )}
              {milestone.status === 'In Progress' && (
                <div className="flex items-center space-x-1 text-yellow-500">
                  <Activity className="w-4 h-4" />
                  <span className="text-sm">In Progress</span>
                </div>
              )}
              {milestone.status === 'Pending' && (
                <div className="flex items-center space-x-1 text-gray-500">
                  <X className="w-4 h-4" />
                  <span className="text-sm">Pending</span>
                </div>
              )}
            </div>
            <p className="text-sm text-gray-500">Due Date: {milestone.dueDate}</p>
            <p className="text-sm text-gray-500">Payment: {milestone.payment}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Milestones;
