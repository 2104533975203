import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

// Public Pages
import Home from '../pages/public/Home';
import Login from '../pages/public/Login';
import Signup from '../pages/public/Signup';
import Pricing from '../pages/public/Pricing';
import NotFound from '../pages/public/NotFound';
import Whitepaper from '../pages/public/Whitepaper';

// User Pages
import Dashboard from '../pages/user/Dashboard';
import Wallets from '../pages/user/Wallets';
import Governance from '../pages/user/Governance';
import Alerts from '../pages/user/Alerts';
import Milestones from '../pages/user/Milestones';
import ProofOfWork from '../pages/user/ProofOfWork';
import Disputes from '../pages/user/Disputes';
import Payroll from '../pages/user/Payroll';
import Analytics from '../pages/user/Analytics';
import Calendar from '../pages/user/Calendar';
import Profile from '../pages/user/Profile';

// Onboarding Pages
import ProfileSetup from '../pages/onboarding/ProfileSetup';
import Workspace from '../pages/onboarding/Workspace';
import Welcome from '../pages/onboarding/Welcome';
import Success from '../pages/onboarding/Success';

// Project Pages
import ProjectCategories from '../pages/projects/ProjectCategories';
import ProjectsList from '../pages/projects/ProjectsList';
import ProjectDetails from '../pages/projects/ProjectDetails';
import OwnerProjectsPage from '../pages/projects/OwnerProjectsPage';

// Messenger Pages
import MessengerPage from '../pages/messenger/MessengerPage';

// Orders Pages
import OrderDetails from '../pages/orders/OrderDetails';
import OrdersList from '../pages/orders/OrdersList';
import DisputeForm from '../pages/orders/DisputeForm';

// Admin Page
import AdminDashboard from '../pages/admin/AdminDashboard';

// Components
import Layout from '../components/Layout';
import PrivateRoute from '../components/PrivateRoute';
import { useAuth } from '../context/AuthContext';

// Admin Route Wrapper
function AdminRoute({ children }) {
  const { user } = useAuth();
  return user?.isAdmin ? children : <Navigate to="/login" replace />;
}

// Main Routes
const AppRoutes = () => {
  return (
    <Routes>
      {/* Public Routes */}
      <Route path="/" element={<Home />} />
      <Route path="/login" element={<Login />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/pricing" element={<Pricing />} />
      <Route path="/whitepaper" element={<Whitepaper />} />


      {/* Onboarding Routes */}
      <Route path="/onboarding/welcome" element={<Welcome />} />
      <Route path="/onboarding/profilesetup" element={<ProfileSetup />} />
      <Route path="/onboarding/workspace" element={<Workspace />} />
      <Route path="/onboarding/success" element={<Success />} />

      {/* Protected Routes with Layout */}
      <Route path="/" element={<Layout />}>
        {/* User Routes */}
        <Route path="whitepaper" element={<PrivateRoute><Whitepaper /></PrivateRoute>} />
        <Route path="wallets" element={<PrivateRoute><Wallets /></PrivateRoute>} />
        <Route path="governance" element={<PrivateRoute><Governance /></PrivateRoute>} />
        <Route path="alerts" element={<PrivateRoute><Alerts /></PrivateRoute>} />
        <Route path="milestones" element={<PrivateRoute><Milestones /></PrivateRoute>} />
        <Route path="proof-of-work" element={<PrivateRoute><ProofOfWork /></PrivateRoute>} />
        <Route path="disputes" element={<PrivateRoute><Disputes /></PrivateRoute>} />
        <Route path="analytics" element={<PrivateRoute><Analytics /></PrivateRoute>} />
        <Route path="payroll" element={<PrivateRoute><Payroll /></PrivateRoute>} />
        <Route path="calendar" element={<PrivateRoute><Calendar /></PrivateRoute>} />
        <Route path="profile" element={<PrivateRoute><Profile /></PrivateRoute>} />

        {/* Messenger Routes */}
        <Route path="/messenger" element={<PrivateRoute><MessengerPage /></PrivateRoute>} />

        {/* Project Routes */}
        <Route path="projects" element={<PrivateRoute><ProjectCategories /></PrivateRoute>} />
        <Route path="projects/owner" element={<PrivateRoute><OwnerProjectsPage /></PrivateRoute>} />
        <Route path="projects/category/:categoryId" element={<PrivateRoute><ProjectsList /></PrivateRoute>} />
        <Route path="projects/:projectId" element={<PrivateRoute><ProjectDetails /></PrivateRoute>} />

        {/* Admin Route */}
        <Route path="admin" element={<AdminRoute><AdminDashboard /></AdminRoute>} />
      </Route>

      {/* Messenger as a Dedicated Layout */}
      <Route path="/messenger" element={<PrivateRoute><MessengerPage /></PrivateRoute>} />

      {/* Orders Route */}
      <Route path="/orders" element={<PrivateRoute><OrdersList /></PrivateRoute>} />
      <Route path="/orders/dev" element={<PrivateRoute><OrdersList isDev={true} /></PrivateRoute>} />
      <Route path="/orders/:orderId" element={<PrivateRoute><OrderDetails /></PrivateRoute>} />

      {/* Catch-all Route */}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default AppRoutes;
