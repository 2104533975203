import React from "react";
import { motion } from "framer-motion";

const AIPoweredPlanningPage = () => {
  // Animation variants for scroll-in effects
  const fadeInUp = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.8 } },
  };

  return (
    <section className="min-h-screen bg-gray-1000 text-white flex items-center justify-center px-1 py-6">
      <div className="max-w-7xl mx-auto grid grid-cols-1 md:grid-cols-2 gap-12 items-center">
        {/* Left Section */}
        <motion.div
          className="text-left"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          variants={fadeInUp}
        >
          <h1 className="text-4xl font-bold mb-6">
            AI-Powered <br />
            Project Planning
          </h1>
          <p className="text-lg text-gray-300 mb-6 leading-relaxed">
            Streamline your workflow and achieve project success with our
            AI-powered planning tools. Create tailored roadmaps in minutes with
            just a few inputs.
          </p>

          {/* Features */}
          <div className="space-y-4">
            <motion.button
              className="flex items-center gap-3 bg-gray-800 text-white px-4 py-2 rounded-lg shadow-lg hover:bg-gray-700"
              whileHover={{ scale: 1.05 }}
            >
              <span>⏳</span> Save Time
            </motion.button>
            <motion.button
              className="flex items-center gap-3 bg-gray-800 text-white px-4 py-2 rounded-lg shadow-lg hover:bg-gray-700"
              whileHover={{ scale: 1.05 }}
            >
              <span>📊</span> Optimize Resources
            </motion.button>
            <motion.button
              className="flex items-center gap-3 bg-gray-800 text-white px-4 py-2 rounded-lg shadow-lg hover:bg-gray-700"
              whileHover={{ scale: 1.05 }}
            >
              <span>📂</span> Stay Organized
            </motion.button>
            <motion.button
              className="flex items-center gap-3 bg-gray-800 text-white px-4 py-2 rounded-lg shadow-lg hover:bg-gray-700"
              whileHover={{ scale: 1.05 }}
            >
              <span>🔧</span> Seamless Adjustments
            </motion.button>
          </div>
        </motion.div>

        {/* Right Section */}
        <motion.div
          className="flex justify-end relative"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          variants={fadeInUp}
        >
          <img
            src="/AI-PowerPlanning.png" // Replace with your actual image path
            alt="AI-Powered Planning Dashboard"
            className="w-auto max-w-[90%] md:max-w-[90%] lg:max-w-[90%] h-auto rounded-lg"
            style={{ objectFit: "contain", marginRight: "-13%" }}
          />
        </motion.div>
      </div>
    </section>
  );
};

export default AIPoweredPlanningPage;
