import React from 'react';

const HeroSection = () => {
  return (
    <section style={styles.container}>
      <div style={styles.textContainer}>
        <h1 style={styles.title}>
          The Future of Freelancing for <span style={styles.highlight}>Blockchain Projects</span>
        </h1>
        <p style={styles.subtitle}>
          AI-powered project management, secure blockchain payments, and decentralized governance—all in one platform.
        </p>
        <div style={styles.buttonsContainer}>
          <a href="https://t.me" target="_blank" rel="noopener noreferrer" style={styles.button}>
            <img src="/icons/telegram-icon.png" alt="Telegram" style={styles.icon} />
          </a>
          <a href="https://example.com" target="_blank" rel="noopener noreferrer" style={styles.button}>
            <img src="/icons/chat-icon.png" alt="Chat" style={styles.icon} />
          </a>
          <a href="/whitepaper" target="_blank" rel="noopener noreferrer" style={{ ...styles.button, ...styles.whitepaper }}>
            Whitepaper
          </a>
        </div>
      </div>
    </section>
  );
};

const styles = {
  container: {
    backgroundColor: '#0B0D17', // Dark background
    color: '#FFFFFF', // White text
    textAlign: 'center',
    padding: '50px 20px',
    position: 'relative',
  },
  textContainer: {
    maxWidth: '800px',
    margin: '0 auto',
  },
  title: {
    fontSize: '2.5rem',
    fontWeight: '700',
    lineHeight: '1.4',
    marginBottom: '20px',
  },
  highlight: {
    color: '#007BFF', // Accent blue color
  },
  subtitle: {
    fontSize: '1.2rem',
    lineHeight: '1.6',
    marginBottom: '30px',
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'center',
    gap: '15px',
  },
  button: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '50px',
    height: '50px',
    backgroundColor: '#1C1F26',
    borderRadius: '8px',
    textDecoration: 'none',
    transition: 'all 0.3s',
  },
  icon: {
    width: '24px',
    height: '24px',
  },
  whitepaper: {
    padding: '10px 20px',
    backgroundColor: '#007BFF', // Blue background
    color: '#FFFFFF',
    fontSize: '1rem',
    borderRadius: '8px',
    fontWeight: '600',
  },
};

export default HeroSection;
