import React from 'react';
import '@fontsource/poppins/400.css'; // For specific weights
import { BrowserRouter as Router, useLocation } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';
import AppRoutes from './routes/AppRoutes';
import Navbar from './components/Layout/Navbar';

// Importing page sections
import HeroSection from './components/Sections/HeroSection';
import WhyDevTrust from './components/Sections/WhyDevTrust';
import HowDevTrustWorks from './components/Sections/HowDevTrustWorks';
import Roadmap from './components/Sections/Roadmap';
import VisionPage from './components/Sections/VisionPage';
import Features from './components/Sections/Features.js';
import Planner from './components/Sections/Planner.js';
import SecurePayments from './components/Sections/SecurePayments.js';
import TokenisedPage from './components/Sections/TokenizedGovernancePage.js';
import AIPowerPlanning from './components/Sections/AIPoweredPlanningPage.js';
import ProjectRoadmapPage from './components/Sections/ProjectRoadmap.js';
import MilestoneTrackerPage from './components/Sections/MilestoneTrackerPage.js';



import FAQ from './components/Sections/FAQ';
import CallToAction from './components/Sections/CallToAction';
import Footer from './components/Sections/Footer';
import Whitepaper from './pages/public/Whitepaper';

const App = () => {
  return (
    <Router>
      <AuthProvider>
        <div className="flex flex-col min-h-screen">
          <ConditionalLayout>
            <AppRoutes />
          </ConditionalLayout>
        </div>
      </AuthProvider>
    </Router>
  );
};

// Conditional layout component
const ConditionalLayout = ({ children }) => {
  const location = useLocation();

  // Show Navbar and Footer only on Home page
  const isHomePage = location.pathname === '/';

  return (
    <>
      {isHomePage && <Navbar />}
      <main
        className={`flex-grow ${
          isHomePage ? 'mt-16' : ''
        }`} // Add margin-top to avoid overlapping
      >
        {isHomePage ? (
          <>
            <HeroSection />
            <VisionPage />
            <Planner />
            <AIPowerPlanning />
            <WhyDevTrust />
            <ProjectRoadmapPage />
            <MilestoneTrackerPage />
            <SecurePayments />
            <TokenisedPage />
            <Features />
            <Roadmap />
            <FAQ />
            <Footer />
          </>
        ) : (
          children
        )}
      </main>
      {/* Footer is always rendered */}
 
    </>
  );
};

export default App;
