import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Menu, X } from 'lucide-react';

const Navbar = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 10) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
<nav
  className={`fixed top-0 left-0 w-full z-50 transition-all duration-300 ${
    isScrolled ? 'bg-gray-900 shadow-lg' : 'bg-transparent'
  }`}
>
  <div className="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
    <div className="flex justify-between items-center h-16">
      {/* Logo */}
      <div className="flex items-center">
        <a href="/" className="flex items-center text-2xl font-bold text-white">
          <img src="logo3.png" alt="DevTrust Logo" className="h-8 w-8 mr-2" />
          DevTrust
        </a>
      </div>

      {/* Desktop Menu */}
      <div className="hidden md:flex items-center space-x-6">
        <a href="#hero-section" className="text-white hover:text-blue-300 font-medium">
          Home
        </a>
        <a href="#why-devtrust" className="text-white hover:text-blue-300 font-medium">
          Vision
        </a>
        <a href="#how-devtrust-works" className="text-white hover:text-blue-300 font-medium">
          Features
        </a>
        <a href="#roadmap" className="text-white hover:text-blue-300 font-medium">
          Roadmap
        </a>
        <a href="#contact-us" className="text-white hover:text-blue-300 font-medium">
          Contact Us
        </a>
      </div>

      {/* CTA Button */}
      <div className="hidden md:flex items-center">
        <a
          href="/whitepaper"
          className="bg-blue-600 text-white px-4 py-2 rounded-lg font-medium hover:bg-blue-700 transition"
        >
          Whitepaper
        </a>
      </div>

      {/* Mobile Menu Toggle */}
      <button
        onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
        className="md:hidden p-2 text-white hover:text-blue-300"
      >
        {isMobileMenuOpen ? <X className="w-6 h-6" /> : <Menu className="w-6 h-6" />}
      </button>
    </div>

    {/* Mobile Menu */}
    {isMobileMenuOpen && (
      <div className="md:hidden mt-4 bg-gray-900 rounded-lg shadow-lg p-4">
        <a href="#hero-section" className="block text-white hover:text-blue-300 mb-2">
          Home
        </a>
        <a href="#why-devtrust" className="block text-white hover:text-blue-300 mb-2">
          Vision
        </a>
        <a href="#how-devtrust-works" className="block text-white hover:text-blue-300 mb-2">
          Features
        </a>
        <a href="#roadmap" className="block text-white hover:text-blue-300 mb-2">
          Roadmap
        </a>
        <a href="#contact-us" className="block text-white hover:text-blue-300 mb-2">
          Contact Us
        </a>
        <a
          href="/whitepaper"
          className="block bg-blue-600 text-white px-4 py-2 rounded-lg text-center hover:bg-blue-700"
        >
          Whitepaper
        </a>
      </div>
    )}
  </div>
</nav>

  );
};

export default Navbar;
