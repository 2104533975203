import React from 'react';
import { useNavigate } from 'react-router-dom';

const Welcome = () => {
  const navigate = useNavigate();

  const handleNext = () => {
    navigate('/onboarding/profile-setup');
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="bg-white p-6 rounded shadow-md w-80">
        <h2 className="text-xl font-bold mb-4 text-gray-800">Welcome to DevTrust!</h2>
        <p className="text-gray-600 mb-6">
          Let’s get started with setting up your account. This will only take a few minutes.
        </p>
        <button
          onClick={handleNext}
          className="w-full bg-blue-600 text-white py-2 rounded hover:bg-blue-700"
        >
          Get Started
        </button>
      </div>
    </div>
  );
};

export default Welcome;
