import React from "react";
import { motion, useTransform, useViewportScroll } from "framer-motion";
import ParticleEffect from "./ParticleEffect";
import { ReactTyped as Typed } from 'react-typed';

const HeroSection = () => {
  const { scrollY } = useViewportScroll();

  const translateY = useTransform(scrollY, [0, 500], [50, 0]);
  const scale = useTransform(scrollY, [0, 500], [0.9, 1.1]);
  const opacity = useTransform(scrollY, [0, 500], [0.8, 1]);

  return (
    <div>
      {/* Section 1: Hero */}
      <section className="relative h-screen bg-gray-1000 text-white overflow-hidden">
        {/* Particle Effect */}
        <ParticleEffect />

        {/* Centered Content */}
        <div className="absolute inset-0 flex flex-col items-center justify-center text-center z-10">
          <motion.h1
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}
            className="text-5xl sm:text-6xl font-bold"
          >
            The Future of Freelancing for
          </motion.h1>
          <motion.h2
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.3, duration: 1 }}
            className="text-5xl sm:text-6xl font-bold text-blue-500 mt-4"
          >
            Blockchain Projects
          </motion.h2>
          <motion.p
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.6, duration: 1 }}
            className="mt-6 text-lg text-gray-300 max-w-2xl"
          >
            AI-powered project management, secure blockchain payments, and
            decentralized governance—all in one platform.
          </motion.p>

          {/* Buttons */}
          <div className="mt-10 flex gap-6">
  <button
    onClick={() => (window.location.href = "/whitepaper")}
    className="px-8 py-3 bg-white text-blue-500 rounded-lg shadow-lg hover:scale-105 transition-transform border-2 border-blue-500"
  >
    Whitepaper
  </button>
  <a
    href="https://t.me/+cSrG-PMImjlmNDFk"
    className="flex items-center justify-center transition-transform transform hover:scale-125"
  >
    <img
      src="/profilePictures/default/telegrambutton.png"
      alt="Telegram"
      className="w-12 h-12"
    />
  </a>
  <a
    href="https://x.com/DevTrust_IO"
    className="flex items-center justify-center transition-transform transform hover:scale-125"
  >
    <img
      src="/profilePictures/default/twitterbutton.png"
      alt="Twitter"
      className="w-12 h-12"
    />
  </a>
</div>


          {/* Typing Text */}
          <div className="mt-10 text-lg text-blue-200 font-medium">
            {" "}
            <Typed
              strings={["Contract Address: Coming soon..."]} // Add typing effect
              typeSpeed={100}
              backSpeed={50}
              loop
            />
          </div>
        </div>
      </section>

      {/* Section 2: Animated Image Below */}
      <section
  className="relative bg-gray-1000 flex items-center justify-center overflow-hidden"
  style={{
    paddingTop: "3rem", // Add padding to create space above
    paddingBottom: "3rem", // Add padding to create space below
    marginTop: "-250px", // Adjust to ensure part of the image is visible on load
  }}
>
  <motion.div
    style={{
      translateY, // Moves the image upward as you scroll
      scale, // Scales the image
      opacity, // Fades in the image

    }}
    className="relative z-0 flex items-center justify-center"
  >
    <img
      src="/laptopDashboard.webp"
      alt="DevTrust Mockup"
      className="w-full max-w-[80%] sm:max-w-[70%] md:max-w-[700px] lg:max-w-[900px] h-auto rounded-lg"
    />
  </motion.div>
</section>




      {/* Glow Effect Style */}
      <style>
        {`
        .glow-effect {
          box-shadow: 0 0 15px 5px rgba(59, 130, 246, 0.5),
                      0 0 30px 10px rgba(59, 130, 246, 0.4),
                      0 0 30px 10px rgba(59, 130, 246, 0.3);
        }
        `}
      </style>
    </div>
  );
};

export default HeroSection;
