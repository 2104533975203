import React, { createContext, useContext, useState, useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';


// Create the context
const AuthContext = createContext();

// Create a provider wrapper component
export function AuthProvider({ children }) {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Check if user is logged in on mount
    const userEmail = localStorage.getItem('userEmail');
    const userId = localStorage.getItem('userId');
    const token = localStorage.getItem('token');
    const isAdmin = localStorage.getItem('isAdmin') === 'true'; // Ensure this is boolean

    if (token) {
      try {
        const decodedToken = jwtDecode(token);

        // Check if the token is expired
        if (decodedToken.exp * 1000 < Date.now()) {
          logout(); // Logout if token has expired
        } else if (userEmail && userId) {
          setUser({
            email: userEmail,
            id: userId,
            token: token,
            isAdmin: isAdmin,
          });
        }
      } catch (err) {
        console.error('Error decoding token:', err);
        logout(); // Logout if token decoding fails
      }
    }
    setLoading(false);
  }, []);

  const login = (email, id, token, isAdmin) => {
    try {
      const decodedToken = jwtDecode(token);

      // Check if token is valid
      if (decodedToken.exp * 1000 < Date.now()) {
        throw new Error('Token is expired');
      }

      localStorage.setItem('userEmail', email);
      localStorage.setItem('userId', id);
      localStorage.setItem('token', token);
      localStorage.setItem('isAdmin', isAdmin.toString()); // Store isAdmin flag
      setUser({ email, id, token, isAdmin });
    } catch (err) {
      console.error('Error during login:', err);
      logout(); // Logout immediately if token is invalid
    }
  };

  const logout = () => {
    localStorage.removeItem('userEmail');
    localStorage.removeItem('userId');
    localStorage.removeItem('token');
    localStorage.removeItem('isAdmin');
    setUser(null);
    window.location.href = '/login'; // Redirect to login page on logout
  };

  if (loading) {
    return <div>Loading...</div>; // Or your custom loading spinner/component
  }

  return (
    <AuthContext.Provider value={{ user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
}

// Create a hook to use the auth context
export function useAuth() {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}
