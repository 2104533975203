import React from 'react';

const WhyDevTrust = () => {
  return (
    <section id="why-devtrust" className="bg-gradient-to-b from-dark-900 to-dark-800 text-white py-20">
      <div className="container mx-auto px-6">
        {/* Title and Description */}
        <div className="text-center mb-12">
          <h2 className="text-4xl font-bold">Why DevTrust?</h2>
          <p className="mt-4 text-lg text-gray-300">
            Clear structure for long-term collaboration
          </p>
        </div>

        {/* Content */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-12 items-center">
          {/* Left Image */}
          <div className="flex justify-center">
            <img
              src="/profilePictures/default/officeDev.png" // Replace with your actual image path
              alt="Team Collaboration"
              className="w-full max-w-md rounded-lg shadow-lg"
            />
          </div>

          {/* Right Content */}
          <div className="flex flex-col justify-center items-center text-center">
            <div>
              <h3 className="text-xl font-bold mb-4">
                AI-Powered Project Management
              </h3>
              <p className="text-lg text-gray-400 mb-8">
                Smarter, faster, and effortless success.
              </p>
            </div>
            <img
              src="/profilePictures/default/whydevtrust.png" // Replace with your actual image path
              alt="Features Overview"
              className="w-full max-w-md rounded-lg shadow-lg"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhyDevTrust;
